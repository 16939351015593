import {useGlobalStore} from "@/stores/GlobalStore";
import NullOrUndefinedHelper from "@/utilities/helpers/NullOrUndefinedHelper";

export default function changeTitle ({next}:any){
    const globalStore = useGlobalStore();
    const defaultSystemCompanyName = globalStore.settings.systemCompanySettings?.companyName;

    if (!NullOrUndefinedHelper(defaultSystemCompanyName)){
        document.title = `1ANAB Web | ${defaultSystemCompanyName}`;
    }

    return next();
}