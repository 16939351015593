import { ErrorType, ErrorLocationType } from "@/utilities/enums/Enums";
import router from "@/router";
import { GeneralResponse } from "@/models/api/Responses";
import { useGlobalStore } from "@/stores/GlobalStore";
import { $t } from "@/utilities/i18n/config";

class ErrorHandler {

    public handleError(error:any, errorLocation:ErrorLocationType){

        const statusCode = error.statusCode ?? error.StatusCode;
        const isResponseError = statusCode > 999;
        const errorType = isResponseError ? ErrorType.warning : ErrorType.error;

        let notify = true;
        if(isResponseError){
            notify = this.reactoToStatusCode(statusCode, error.companyName ?? "");

            if(notify){
                this.notifyFailedCommunication(errorType, $t(`error.status.${statusCode}`), errorLocation);
            }
        }
        else{
            notify = this.reactoToStatusCode(statusCode);

            if(notify){

                if(statusCode === 403) {
                    this.notifyFailedCommunication(errorType, $t("error.status.accessDenied"), errorLocation);
                } else {
                    this.notifyFailedCommunication(errorType, $t("error.status.general", {message:error.statusDescription ?? error.StatusDescription}), errorLocation);
                }

            }
        }
    }


    /*  PRIVATE  */


    private notifyFailedCommunication(type:ErrorType, text:string, errorLocation:ErrorLocationType) {
        const globalStore = useGlobalStore();

        globalStore.gridDataIsLoading = false;

        globalStore.error = {
            location: errorLocation,
            text: text,
            type: type,
        };
    }


    /*
        handles special status codes and what to do when encountering them
        returns if the calling method needs to notify the user
    */
    private reactoToStatusCode(status:number, additionalInfo:string = ""):boolean {
        switch (status) {

            case 9085: // API error code for cookie/refreshtoken expired OR empty
            case 401:{
                this.notifyFailedCommunication(ErrorType.error, $t('global.noValidToken'), ErrorLocationType.login);
                router.push("/login");

                return false;
            }
            case 4028:{
                this.notifyFailedCommunication(ErrorType.error, $t(`error.status.${status}`, {companyName: additionalInfo}), ErrorLocationType.popup);
                return false;
            }

            default:{
                return true;
            }
        }
    }
}

export default new ErrorHandler();
