import { FilterMethodBuilder } from "@/utilities/helpers/FilterMethodBuilder";
import { UserPreference } from "@/models/user/UserPreference";
import { RegistrationStatusType, TableType } from "@/utilities/enums/Enums";
import { useGlobalStore } from "@/stores/GlobalStore";

class FilterService {
    filterBuilder: FilterMethodBuilder;

    constructor() {
        this.filterBuilder = new FilterMethodBuilder();
    }

    /*
        returns a method that the grid can filter by
        optionally can be used to return a method to filter with tmp-filter-selection
    */
    buildFilter(tableName:string, useTmpFilters:boolean = false) {
        const globalStore = useGlobalStore()

        const filters = useTmpFilters
            ? globalStore.tmpPreferences.tmpUserFilters
            : globalStore.userPreferences.userFilters;
        const filterMethodDict = this.getFilterMethodDict(tableName, filters, useTmpFilters);

        // return userPreference-Filter as a method for bryntums filterBy-Method
        return (record:any) => {
            // convert {<propName>:[propFilterMethods], <propName>:[propFilterMethods], ...} to [[propFilterMethods], [propFilterMethods], ...]
            const methodList:any = [];
            Object.keys(filterMethodDict).forEach((k:any) => methodList.push(filterMethodDict[k]));

            let result = true;

            methodList.forEach((propMethodList:any) => {
                let propFilterResult = false;

                // concatinate multiple filters for the same property via '||'
                propMethodList.forEach((method:any) => {
                    propFilterResult = propFilterResult || method(record);
                });

                // concatinate propFilterResult with other propFilterResults via '&&'
                result = result && propFilterResult;
            });

            return result;
        };
    }


    /* ---- PRIVATE ---- */


    /*
        returns a dict for all properties to filter (key == propertyName and value == arrayOfFolterMethods)
    */
    private getFilterMethodDict (tableName:string, filters:Array<UserPreference>, useTmpFilters:boolean = false): any {
        // filter for only table-relevant filters and also ignore filters for hidden columns
        const tableFilters = filters.filter((f:UserPreference) => f.tableName === tableName && !this.isForHiddenColumn(f, useTmpFilters));

        switch(tableName) {
            case TableType[TableType.location]: {
                return this.getLocationMethods(tableFilters);
                break;
            }
            case TableType[TableType.user]: {
                return this.getUserMethods(tableFilters);
                break;
            }
            case TableType[TableType.register]: {
                return this.getRegisterReportMethods(tableFilters);
                break;
            }
            case TableType[TableType.registerLocation]: {
                return this.getRegisterLocationReportMethods(tableFilters);
                break;
            }
            case TableType[TableType.disdi]:{
                return this.getDisdiMethods(tableFilters);
                break;
            }
            default: {
                console.log("[FilterService] [getFilterMethodDict]: unknown table");
                return {};
                break;
            }
        }
    }

    /*
        returns array of filter-methods per property to apply to the record
    */
    private getLocationMethods(filters:Array<UserPreference>): any {
        const result = this.initFilterList(filters);

        // fill with correct filter-methods
        filters.forEach((f:UserPreference) => {
            switch (f.attributeName) {
                case "registrationStatus": {
                    const convertedAttrValue = (RegistrationStatusType[f.attributeValue as keyof typeof RegistrationStatusType]);
                    result[f.attributeName].push(this.filterBuilder.location.filterStatus(convertedAttrValue));

                    break;
                }
                case "controlCenterId": {
                    const convertedAttrValue = parseInt(f.attributeValue);
                    if(!isNaN(convertedAttrValue)) result[f.attributeName].push(this.filterBuilder.location.filterControlCenter(convertedAttrValue));

                    break;
                }
                case "group1Id": {
                    const convertedAttrValue = parseInt(f.attributeValue);
                    if(!isNaN(convertedAttrValue)) result[f.attributeName].push(this.filterBuilder.location.filterGroup1(convertedAttrValue));

                    break;
                }
                case "group2Id": {
                    const convertedAttrValue = parseInt(f.attributeValue);
                    if(!isNaN(convertedAttrValue)) result[f.attributeName].push(this.filterBuilder.location.filterGroup2(convertedAttrValue));

                    break;
                }
                default: {
                    console.log("attribute not known, skipping filter");

                    break;
                }
            }
        });

        return result;
    }

    /*
        returns array of filter-methods per property to apply to the record
    */
    private getUserMethods(filters:Array<UserPreference>):any{
        const result = this.initFilterList(filters);

        // fill with correct filter-methods
        filters.forEach((f:UserPreference) => {
            switch (f.attributeName) {
                case "roles": {
                    const convertedAttrValue = f.attributeValue;
                    result[f.attributeName].push(this.filterBuilder.user.filterRoles(convertedAttrValue));

                    break;
                }
                case "isActive": {
                    const convertedAttrValue = f.attributeValue === "true";
                    result[f.attributeName].push(this.filterBuilder.user.filterIsActive(convertedAttrValue));

                    break;
                }
                case "kid": {
                    const convertedAttrValue = f.attributeValue === "true";
                    result[f.attributeName].push(this.filterBuilder.user.filterHasKID(convertedAttrValue));

                    break;
                }
                default: {
                    console.log("attribute not known, skipping filter");

                    break;
                }
            }
        });

        return result;
    }

    /*
        returns array of filter-methods per property to apply to the record
    */
    private getRegisterReportMethods(filters:Array<UserPreference>): any {
        const result = this.initFilterList(filters);

        // fill with correct filter-methods
        filters.forEach((f:UserPreference) => {
            switch (f.attributeName) {
                case "isActive": {
                    const convertedAttrValue = f.attributeValue === "Active";
                    result[f.attributeName].push(this.filterBuilder.register.filterIsActive(convertedAttrValue));

                    break;
                }
                case "isToday": { //TODO read value? needed?
                    result[f.attributeName].push(this.filterBuilder.register.filterToday());

                    break;
                }
                default: {
                    console.log("attribute not known, skipping filter");

                    break;
                }
            }
        });

        return result;
    }

    /*
        returns array of filter-methods per property to apply to the record
     */
    private getRegisterLocationReportMethods(filters:Array<UserPreference>): any{
        const result = this.initFilterList(filters);

        // fill with correct filter-methods
        filters.forEach((f:UserPreference) => {
            switch (f.attributeName) {
                case "registrationStatus.isLocationBusy": {
                    const convertedAttrValue = f.attributeValue === "Active";
                    result[f.attributeName].push(this.filterBuilder.registerLocation.filterIsActive(convertedAttrValue));

                    break;
                }
                case "isToday": {
                    result[f.attributeName].push(this.filterBuilder.registerLocation.filterToday());

                    break;
                }
                case "controlCenterId": {
                    const convertedAttrValue = parseInt(f.attributeValue);
                    if(!isNaN(convertedAttrValue)) result[f.attributeName].push(this.filterBuilder.registerLocation.filterControlCenter(convertedAttrValue));

                    break;
                }
                case "group1Id": {
                    const convertedAttrValue = parseInt(f.attributeValue);
                    if(!isNaN(convertedAttrValue)) result[f.attributeName].push(this.filterBuilder.registerLocation.filterGroup1(convertedAttrValue));

                    break;
                }
                case "group2Id": {
                    const convertedAttrValue = parseInt(f.attributeValue);
                    if(!isNaN(convertedAttrValue)) result[f.attributeName].push(this.filterBuilder.registerLocation.filterGroup2(convertedAttrValue));

                    break;
                }
                default: {
                    console.log("attribute not known, skipping filter");

                    break;
                }
            }
        });

        return result;
    }

    private getDisdiMethods(filters:Array<UserPreference>): any{
        const result = this.initFilterList(filters);

        for (const f of filters){
            switch (f.attributeName) {
                case "categoryId": {
                    result[f.attributeName].push(this.filterBuilder.disdi.filterCategory(f.attributeValue));

                    break;
                }
                default: {
                    console.log("attribute not known, skipping filter");

                    break;
                }
            }
        }

        return result;
    }


    /*
        init result as dict where 'key == propName' && 'value == array'
    */
    private initFilterList(filters:Array<UserPreference>){
        return filters.reduce((initResult:any , filter:UserPreference)=>{
            const attr = filter.attributeName;

            if (initResult[attr] === undefined) initResult[attr] = [];

            return initResult;
        }, {});
    }

    /*
        translate from attributeName to col-field-id and return if the filter is for a hidden column
    */
    private isForHiddenColumn(filter:UserPreference, useTmpFilters:boolean = false) {
        let attribute = "";

        switch(filter.attributeName){
            case "group2Id":{
                attribute = "group2Name";
                break;
            }
            case "group1Id":{
                attribute = "group1Name";
                break;
            }
            case "controlCenterId":{
                attribute = "controlCenterName";
                break;
            }
            default:{
                attribute = filter.attributeName;
                break;
            }
        }

        const globalStore = useGlobalStore();

        const colStore = useTmpFilters
            ? globalStore.tmpPreferences.tmpUserHiddenColumns
            : globalStore.userPreferences.userHiddenColumns;

        return colStore.some((hc:UserPreference) => hc.attributeName === attribute);
    }
}

export default new FilterService();
