export class CompanyInfo{
    id:string = "";
    name:string = "";
    hasAdmin:boolean = false;
    phoneNumber:string = "";
    zip:string = "";
    city:string = "";
    street:string = "";
    streetnumber:string = "";
    userId:string = "";
    contactPersonName:string = "";
    companyIdCode:string = "";
    info:string = "";
    dialCode:string = "";
    contactPersonEmail:string = "";
    isContactPerson:boolean = false;
    contactPersonPhoneNumber:string = "";
    idCode:string = "";
}