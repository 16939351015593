<template>
  <div class="w-full h-full">
    <div class="flex flex-col h-full justify-center items-center">
      <LoadingSpinner/>
    </div>
  </div>
</template>
<script setup>
import {onMounted} from "vue";
import OAuthService from "@/utilities/services/OAuthService";
import {useGlobalStore} from "@/stores/GlobalStore";
import {disdiClientConfig} from "@/AppConfig";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

const globalStore = useGlobalStore();

onMounted(async ()=> {
  const oAuthService = new OAuthService(globalStore.getAuthorityUrl(), disdiClientConfig.clientId, disdiClientConfig.scopes, disdiClientConfig.callback, disdiClientConfig.silent);
  try {
    await oAuthService.refreshTokensSilently();
  }
  catch (e) {
    console.error(e);
    return;
  }
})

</script>
