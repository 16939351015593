<template>
  <div v-if="userList.length > 0" class="h-60">

    <div class="groupInputs flex">
      <div class="inputRow">
        <div class="inputTitle">
          {{ $t('popups.register.user') }} *
        </div>
        <div class="inputField flex-grow">
          <Select :searchable="true" :options="userList" @selectChanged="userSelected"/>
        </div>
      </div>
      <div class="inputRow">
        <div class="inputTitle">
					{{ $t('popups.register.location') }} *
        </div>
        <div class="inputField flex-grow">
          <Select :searchable="true" :options="locationList" @selectChanged="locationSelected" :disabled=!showReg v-if="locationList.length > 0"/>
        </div>
      </div>
    </div>

    <div v-if="showReg && selectedLocation?.id !== undefined">
      <div class="grid grid-cols-2 gap-4 w-full">
        <div class="inputRow">
          <div class="inputTitle">
						{{ $t('popups.register.guestAmount') }}
          </div>
          <div class="inputField flex-grow w-full">
            <input class="w-full" type="number" :value="selectedGuestCount" min="0" step="1" @input="guestCountSelected">
          </div>
        </div>
        <div class="inputRow" v-if="selectedLocation?.hasActivitySet && globalStore.settings.activitySettings.activitesEnabled">
          <div class="inputTitle">
						{{ $t('popups.register.activity') }} *
          </div>
          <div class="inputField flex-grow">
            <Select :searchable="false" :options="activityList" @selectChanged="activitySelected" v-if="activityList.length > 0"/>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showReg && selectedLocation?.id !== undefined">
      <div class="groupInputs flex">
        <button @click="regUser" class="primary ml-1" :disabled="loading || cannotReg">
					<TextOrSpinner :text="t('popups.register.register')" :spin="loading"/>
				</button>
      </div>
    </div>

    <div v-if="showUnreg">
      <div class="groupInputs flex  ml-1 mb-4">
        <div class="inputTitle">
          <i>{{ $t('popups.register.adressOfLocation') }}:</i><br/>
          {{selectedLocation.name}}<br/>
          {{selectedLocation.street}} {{selectedLocation.streetNumber}}<br/>
          {{selectedLocation.postalCode}} {{selectedLocation.city}}
        </div>
      </div>
      <div class="groupInputs flex">
        <button class="primary ml-1" @click="unregUser" :disabled="loading || cannotUnreg">
          <TextOrSpinner :text="t('popups.register.unregister')" :spin="loading"/>
        </button>
      </div>
    </div>

  </div>
  <div class="text-center" v-else>
    <LoadingSpinner/>
  </div>
</template>

<script setup>
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";
import {ErrorLocationType} from "@/utilities/enums/Enums";
import Select from "@/components/inputs/Select";
import {useGlobalStore} from "@/stores/GlobalStore";
import LoadingSpinner from "@/components/LoadingSpinner";
import {ActivityInfo} from "@/models/activity/ActivityInfo";
import {useI18n} from "vue-i18n";
import {RegistrationService} from "@/utilities/services/entities/RegistrationService";
import {ActivityService} from "@/utilities/services/entities/ActivityService";
import {SettingService} from "@/utilities/services/entities/SettingService";
import {CompanyWorkerService} from "@/utilities/services/entities/CompanyWorkerService";
import {UserService} from "@/utilities/services/entities/UserService";
import {LocationService} from "@/utilities/services/entities/LocationService";
import TextOrSpinner from "@/components/TextOrSpinner";
import {DisdiService} from "@/utilities/services/entities/DisdiService";


const globalStore = useGlobalStore();

const userList = ref([]);
const locationList = ref([]);
const currentOpenRegistrations = ref([]);
const activityList = ref([]);
let loading = ref(false);

const { t } = useI18n();
const activityService = new ActivityService();
const settingService = new SettingService();
const companyWorkerService = new CompanyWorkerService();
const registrationService = new RegistrationService();
const userService = new UserService();
const locationService = new LocationService();
const disdiService = new DisdiService();

const selectedUser = ref(null);
const selectedLocation = ref(null);
const selectedActivity = ref(null);
const selectedGuestCount = ref(0);

const showReg = ref(false);
const showUnreg = ref(false);

const userSelected = (async (id)=>{
  loading.value = true;

  if(id === null){
    await setLocationList();

    showReg.value = false;
    showUnreg.value = false;

    loading.value = false;
    return;
  }

  selectedUser.value = userList.value.find(u => u.id === id);

  const openRegForUser = currentOpenRegistrations.value.find(oReg => oReg.id === selectedUser.value.id);

  if(openRegForUser !== undefined){
    showReg.value = false;
    showUnreg.value = true;

    selectedLocation.value = locationList.value.find(l => l.name === openRegForUser.registeredLocationInfo[0].locationName);
  }
  else {
    showReg.value = true;
    showUnreg.value = false;

    selectedLocation.value = null;
    selectedActivity.value = null;
  }

  loading.value = false;
});

const locationSelected = (async (id)=>{
  selectedLocation.value = locationList.value.find(l => l.id === id);
  if (selectedLocation.value?.hasActivitySet && globalStore.settings.activitySettings.activitesEnabled){
    selectedActivity.value = null;
    await setActivityList();
  }
});

const guestCountSelected = (async ({target}) =>{
  selectedGuestCount.value = target.valueAsNumber < 0 ? 0 : target.valueAsNumber;
});

const activitySelected = async (id)=>{
  selectedActivity.value = activityList.value.find(a => a.id === id);
};

const cannotReg = computed(()=>{
  return selectedLocation.value?.hasActivitySet && globalStore.settings.activitySettings.activitesEnabled
      ? selectedUser.value?.id === undefined || selectedLocation.value?.id === undefined || selectedActivity.value?.id === undefined
      : selectedUser.value?.id === undefined || selectedLocation.value?.id === undefined
})

const cannotUnreg = computed(()=> {
  return selectedUser.value?.id === undefined || selectedLocation.value?.id === undefined
})

const unregUser = async () => {
  if (selectedUser.value?.id === undefined || selectedLocation.value?.id === undefined) return;
  loading.value = true;

  const unregResp = await registrationService.postUnRegisterUser(selectedUser.value.id, selectedLocation.value.id);
  if(unregResp === null || unregResp === undefined || unregResp.statusCode !== 0){
    ErrorHandler.handleError(unregResp, ErrorLocationType.popup);
		loading.value = false;
    return;
  }

	loading.value = false;
  globalStore.showSuccess(t("popups.register.unregistered"));

  globalStore.closeModal();
};

const regUser = async () => {
  if (selectedUser.value?.id === undefined || selectedLocation.value?.id === undefined) return;
  if (selectedLocation.value?.hasActivitySet && globalStore.settings.activitySettings.activitesEnabled && selectedActivity.value?.id === undefined) return;
  loading.value = true;

  const regResp = await registrationService.postRegisterUser(selectedUser.value.id, selectedLocation.value.id, selectedActivity.value?.name, selectedGuestCount.value);
  if(regResp === null || regResp === undefined || regResp.statusCode !== 0){
    ErrorHandler.handleError(regResp, ErrorLocationType.popup);
		loading.value = false;
    return;
  }

  // also call disdi API if location has disdi-enabled to create empty DISDI-Entry
  if(selectedLocation.value.hasDisdi){
    const regIdResp = await registrationService.getOpenRegistrationForUser(selectedUser.value.id);
    if(regIdResp === null || regIdResp === undefined || regIdResp.statusCode !== 0){
      ErrorHandler.handleError(regIdResp, ErrorLocationType.popup);
      loading.value = false;
      return;
    }

    const createDisdiResp = await disdiService.createEmptyDisdiEntry(selectedLocation.value.secondaryId, regIdResp.regList[0].id, selectedUser.value.id);
    if(createDisdiResp === null || createDisdiResp === undefined || createDisdiResp.statusCode !== 0){
      ErrorHandler.handleError(createDisdiResp, ErrorLocationType.popup);
      loading.value = false;
      return;
    }
  }

	loading.value = false;
  globalStore.showSuccess(t("popups.register.registered"));

  globalStore.closeModal();
};

const setCurrentOpenRegs = async ()=>{
  currentOpenRegistrations.value = [];

  const openRegsResp = await registrationService.getOpenRegistrationsByUsers();
  if(openRegsResp === null || openRegsResp === undefined || openRegsResp.statusCode !== 0){
    ErrorHandler.handleError(openRegsResp, ErrorLocationType.popup);
    return;
  }

  currentOpenRegistrations.value = openRegsResp.registrationInfo;
};

const setUserList = async ()=>{
  userList.value = [];

  const userResp = await userService.getUsers("selectable");
  if(userResp === null || userResp === undefined || userResp.statusCode !== 0){
    ErrorHandler.handleError(userResp, ErrorLocationType.popup);
    return;
  }

  const companyWorkers = await getCompleteCompanyWorkerListForSystem();
  userResp.userInfo = userResp.userInfo.concat(companyWorkers);

  userList.value = userResp.userInfo.sort((a,b)=>{
    const nameA = a.firstName.toLowerCase();
    const nameB = b.firstName.toLowerCase();

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });
};

const setLocationList = async ()=>{
  locationList.value = [];

  const locations = await locationService.getLocations("selectable", ErrorLocationType.popup);

  locationList.value = locations.sort((a,b)=>{
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });
};

const setActivityList = async ()=>{
  activityList.value = [];

  const activityResp = await activityService.getActivitySetActivities(selectedLocation.value.activitySetId);
  if(activityResp === null || activityResp === undefined || activityResp.statusCode !== 0){
    ErrorHandler.handleError(activityResp, ErrorLocationType.popup);
    return;
  }

  activityList.value = activityResp.activityInfo.map(a => {
    const converted = new ActivityInfo();
    converted.id = a.activityId;
    converted.name = a.activityText;

    return converted;
  });

  const defaultActivityResp = await settingService.getDefaultActivityText();
  if(defaultActivityResp === null || defaultActivityResp === undefined || defaultActivityResp.statusCode !== 0){
    ErrorHandler.handleError(defaultActivityResp, ErrorLocationType.popup);
    return;
  }

  activityList.value.push({id:-2, name:defaultActivityResp.text});
}

const getCompleteCompanyWorkerListForSystem = async ()=>{
  const companyWorkerResp = await companyWorkerService.getCompanyWorkersForCurrentSystem();
  if(companyWorkerResp === null || companyWorkerResp === undefined || companyWorkerResp.statusCode !== 0){
    ErrorHandler.handleError(companyWorkerResp, ErrorLocationType.popup);
    return;
  }

  return companyWorkerResp.userInfos;
};

onMounted(async () => {
  await setCurrentOpenRegs();

  await setUserList();

  await setLocationList();
})

onBeforeUnmount(async ()=>{
  await globalStore.modal.action();
});

</script>

<style scoped>

</style>
