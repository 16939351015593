export const anabPolicies = [
    {
        "Name": "UserAdministrator.User.Navigation.Show",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 2, Datenpflege 3"
    },
    {
        "Name": "UserAdministrator.User.Read",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 2, Datenpflege 3"
    },
    {
        "Name": "UserAdministrator.User.Create",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "UserAdministrator.User.Edit",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "UserAdministrator.User.Delete",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "UserAdministrator.User.Role.Edit",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "UserAdministrator.User.CSV.Export",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "UserAdministrator.User.CSV.Import",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "RegistrationReport.User.Logout",
        "Roles": "Administrator, Netzleitstelle, Standortverantwortlicher"
    },
    {
        "Name": "RegistrationReport.Read",
        "Roles": "Administrator, Netzleitstelle, Standortverantwortlicher"
    },
    {
        "Name": "RegistrationReport.Navigation.Show",
        "Roles": "Administrator, Netzleitstelle, Standortverantwortlicher"
    },
    {
        "Name": "Registration.Manual",
        "Roles": "Administrator, Netzleitstelle, Standortverantwortlicher"
    },
    {
        "Name": "ActivitySets.Show",
        "Roles": "Administrator"
    },
    {
        "Name": "Notification.Navigation.Show",
        "Roles": "Administrator"
    },
    {
        "Name": "Setting.Read",
        "Roles": "Administrator"
    },
    {
        "Name": "Setting.Navigation.Show",
        "Roles": "Administrator"
    },
    {
        "Name": "Setting.Edit",
        "Roles": "Administrator"
    },
    {
        "Name": "ProviderManagementPolicy.Read",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 2, Datenpflege 3"
    },
    {
        "Name": "ProviderManagementPolicy.Create",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 2"
    },
    {
        "Name": "ProviderManagementPolicy.Edit",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 2"
    },
    {
        "Name": "ProviderManagementPolicy.Delete",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 2"
    },
    {
        "Name": "ProviderManagementPolicy.CompanyWorker.Navigation.Show",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 2, Datenpflege 3"
    },
    {
        "Name": "ProviderManagementPolicy.CompanyWorker.Read",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 2, Datenpflege 3"
    },
    {
        "Name": "ProviderManagementPolicy.CompanyWorker.Status",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 2"
    },
    {
        "Name": "ProviderManagementPolicy.CompanyWorker.Create",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 2"
    },
    {
        "Name": "ProviderManagementPolicy.CompanyWorker.Delete",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 2"
    },
    {
        "Name": "ProviderManagementPolicy.CompanyWorker.Edit",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 2"
    },
    {
        "Name": "Region.Navigation.Show",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "Region.Read",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "Region.Create",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "Region.Edit",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "Region.Delete",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "ControlCenter.Navigation.Show",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "ControlCenter.Read",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "ControlCenter.Create",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "ControlCenter.Edit",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "ControlCenter.Delete",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "SafetyInstruction.Read",
        "Roles": "Administrator, Datenpflege 1, Netzleitstelle, Standortverantwortlicher"
    },
    {
        "Name": "SafetyInstruction.Navigation.Show",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "SafetyInstruction.Create",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "SafetyInstruction.Edit",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "SafetyInstruction.Delete",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "LocationManagement.Navigation.Show",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 4"
    },
    {
        "Name": "Location.Read",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 4"
    },
    {
        "Name": "Location.Create",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "Location.Edit",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "Location.Delete",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "Location.CSV.Export",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "Location.CSV.Import",
        "Roles": "Administrator, Datenpflege 1"
    },
    {
        "Name": "LocationReport.Navigation.Show",
        "Roles": "Administrator, Netzleitstelle, Standortverantwortlicher"
    },
    {
        "Name": "LocationReport.Message.Send",
        "Roles": "Administrator, Netzleitstelle, Standortverantwortlicher"
    },
    {
        "Name": "CoupledSystem.Read",
        "Roles": "Administrator"
    },
    {
        "Name": "CoupledSystem.Edit",
        "Roles": "Administrator"
    },
    {
        "Name": "CoupledSystem.Create",
        "Roles": "Administrator"
    },
    {
        "Name": "CoupledSystem.Delete",
        "Roles": "Administrator"
    },
    {
        "Name": "Admin.CantSee",
        "Roles": "Datenpflege 1"
    },
    {
        "Name": "Twilio.VoiceCall",
        "Roles": "Administrator,Datenpflege 1, Datenpflege 2, Datenpflege 3, Datenpflege 4"
    },
    {
        "Name": "Twilio.ConferenceVoiceCall",
        "Roles": "Administrator,Datenpflege 1, Datenpflege 2, Datenpflege 3, Datenpflege 4"
    },
    {
        "Name": "Category.Read",
        "Roles": "Administrator,Datenpflege 1"
    },
    {
        "Name": "Category.Edit",
        "Roles": "Administrator,Datenpflege 1"
    },
    {
        "Name": "Category.Create",
        "Roles": "Administrator,Datenpflege 1"
    },
    {
        "Name": "Category.Delete",
        "Roles": "Administrator,Datenpflege 1"
    },
    {
        "Name": "CombinedPolicies.ReadSelectableUser",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 2, Datenpflege 3, Netzleitstelle"
    },
    {
        "Name": "CombinedPolicies.ReadSelectableLocation",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 4, Netzleitstelle"
    },
    {
        "Name": "CombinedPolicies.ReadSelectableCompanyWorker",
        "Roles": "Administrator, Datenpflege 1, Datenpflege 2, Netzleitstelle"
    },
    {
        "Name": "CombinedPolicies.ReadSelectableActivities",
        "Roles": "Administrator, Netzleitstelle"
    },
    {
        "Name": "CombinedPolicies.ReadSelectableSettings",
        "Roles": "Administrator, Netzleitstelle"
    },
    {
        "Name": "Disdi.ReadAttachments",
        "Roles": "Administrator"
    },
    {
        "Name": "Disdi.AllRoles",
        "Roles": "Administrator,systemadmin,Netzleitstelle,Monteur,Datenpflege 1,Datenpflege 2,Datenpflege 3,Datenpflege 4,Datenpflege 5,Standortverantwortlicher"
    },
    {
        "Name": "Disdi.Read",
        "Roles": "DYNAMIC"
    },
    {
        "Name": "Disdi.LocationPropertyEdit",
        "Roles": "DYNAMIC"
    }
];

export const permissionColumn = [
    "activityText",
    "hasDisdi",
    "disdi",
    "infoButton"
]

export const defaultSpaceAroundTableOnPage = 230;

export const phoneNumberPlaceholder = "+491234567891"

export const countryDialCodes =[
    {
        "name": "Deutschland (+49)",
        "dialCode": "+49",
        "id": "DE"
    },
    {
        "name": "Griechenland (+30)",
        "dialCode": "+30",
        "id": "GRC"
    },
    {
        "name": "Niederlande (+31)",
        "dialCode": "+31",
        "id": "NL"
    },
    {
        "name": "Belgien (+32)",
        "dialCode": "+32",
        "id": "BEL"
    },
    {
        "name": "Frankreich (+33)",
        "dialCode": "+33",
        "id": "FRA"
    },
    {
        "name": "Spanien (+34)",
        "dialCode": "+34",
        "id": "ESP"
    },
    {
        "name": "Portugal (+351)",
        "dialCode": "+351",
        "id": "POR"
    },
    {
        "name": "Luxemburg (+352)",
        "dialCode": "+352",
        "id": "LUX"
    },
    {
        "name": "Irland (+353)",
        "dialCode": "+353",
        "id": "IRE"
    },
    {
        "name": "Malta (+356)",
        "dialCode": "+356",
        "id": "MLT"
    },
    {
        "name": "Zypern (+357)",
        "dialCode": "+357",
        "id": "CYP"
    },
    {
        "name": "Finnland (+358)",
        "dialCode": "+358",
        "id": "FIN"
    },
    {
        "name": "Bulgarien (+359)",
        "dialCode": "+359",
        "id": "BGR"
    },
    {
        "name": "Ungarn (+36)",
        "dialCode": "+36",
        "id": "HUN"
    },
    {
        "name": "Litauen (+370)",
        "dialCode": "+370",
        "id": "LTU"
    },
    {
        "name": "Lettland (+371)",
        "dialCode": "+371",
        "id": "LVA"
    },
    {
        "name": "Estland (+372)",
        "dialCode": "+372",
        "id": "EST"
    },
    {
        "name": "Ukraine (+380)",
        "dialCode": "+380",
        "id": "POR"
    },
    {
        "name": "Kroatien (+385)",
        "dialCode": "+385",
        "id": "CRO"
    },
    {
        "name": "Slowenien (+386)",
        "dialCode": "+386",
        "id": "SVN"
    },
    {
        "name": "Italien (+39)",
        "dialCode": "+39",
        "id": "ITA"
    },
    {
        "name": "Rumänien (+40)",
        "dialCode": "+40",
        "id": "ROU"
    },
    {
        "name": "Schweiz (+41)",
        "dialCode": "+41",
        "id": "SUI"
    },
    {
        "name": "Tschechische Republik (+420)",
        "dialCode": "+420",
        "id": "CZE"
    },
    {
        "name": "Slowakei (+421)",
        "dialCode": "+421",
        "id": "SVK"
    },
    {
        "name": "Österreich (+43)",
        "dialCode": "+43",
        "id": "AUT"
    },
    {
        "name": "England (+44)",
        "dialCode": "+44",
        "id": "ENG"
    },
    {
        "name": "Schottland (+44)",
        "dialCode": "+44",
        "id": "SCO"
    },
    {
        "name": "Dänemark (+45)",
        "dialCode": "+45",
        "id": "DK"
    },
    {
        "name": "Schweden (+46)",
        "dialCode": "+46",
        "id": "SWE"
    },
    {
        "name": "Norwegen (+47)",
        "dialCode": "+47",
        "id": "NOR"
    },
    {
        "name": "Polen (+48)",
        "dialCode": "+48",
        "id": "PL"
    },
    {
        "name": "Türkei (+90)",
        "dialCode": "+90",
        "id": "TUR"
    }
];

export const checkboxColumnConfig = {
    id: "checkColumn",
    hideable: false,
    draggable: true,
    resizable: false,
    minWidth: 64,
    width: 64
};

export const forbiddenStartupRouteNames =[
    "ProviderManagementDetails",
    "Home",
    "Login",
    "Changelog",
    "Error",
    "Logout",
    "404",
    "Settings",
    "DisdiOtpLogin",
    "DisdiMaintenance",
    "DisdiView",
    "DisdiExit",
    "OAuthCallback",
    "OAuthSilentCallback",
    "DisdioAuthCallback",
    "DisdioAuthSilentCallback",
    "SsoOAuthCallback",
    "SsoOAuthSilentCallback",
    "SamlCallback"
]

export const disdiClientConfig = {
    clientId: "DisdiClient",
    scopes: "openid profile disdiMaintain accountsvc",
    callback: "/disdi/oAuthCallback",
    silent: "/disdi/oAuthCallback/silent"
}

export const baseURL = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test' && typeof console !== 'undefined'
    // ? "http://localhost:59572"
    ? "http://localhost:48627"
    : window.location.origin;
