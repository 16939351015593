import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {
    AddedCompanyWorkerResponse,
    CompamyWorkersUserInfoResponse,
    CompanyWorkerSearchResponse,
    CompanyWorkersResponse,
    ExistsResponse,
    GeneralResponse
} from "@/models/api/Responses";
import {HttpType} from "@/utilities/enums/Enums";
import {CompanyWorkerRequest, PhoneNumberRequest} from "@/models/api/Requests";
import {CompanyWorkerInfo} from "@/models/company/CompanyWorkerInfo";
import NullOrUndefinedHelper from "@/utilities/helpers/NullOrUndefinedHelper";
import {CompanyInfo} from "@/models/company/CompanyInfo";

export class CompanyWorkerService {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }

    public async getCompanyWorkerInfoForCompany(id: string): Promise<CompanyWorkersResponse> {
        const uri = this.uriHelper.company.getCompanyWorkerInfoForCompanyPath(id);

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async getCompanyWorkersForCurrentSystem(): Promise<CompamyWorkersUserInfoResponse> {
        const uri = this.uriHelper.company.getCompanyWorkersForCurrentSystemPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async postSearchCompanyWorkerByPhonenumber(companyId: string, dialCode: string, phoneNumber: string): Promise<CompanyWorkerSearchResponse> {
        const uri = this.uriHelper.company.getSearchCompanyWorkerByPhonenumberPath(companyId);
        const requestBody = new PhoneNumberRequest(dialCode, phoneNumber);

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    public async addorUpdateCompanyWorker(companyId: string, companyWorker: CompanyWorkerInfo): Promise<AddedCompanyWorkerResponse> {
        const uri = NullOrUndefinedHelper(companyWorker.id)
            ? this.uriHelper.company.getCompanyWorkerPath(companyId)
            : this.uriHelper.company.getCompanyWorkerByIdPath(companyId, companyWorker.id);

        const requestBody = new CompanyWorkerRequest(companyWorker);

        const reqType = NullOrUndefinedHelper(companyWorker.id)
            ? HttpType.post
            : HttpType.put;

        return await this.webCaller.HttpSend(reqType, uri, requestBody);
    }

    public async deleteCompanyWorker(companyId: string, userId: string): Promise<GeneralResponse> {
        const uri = this.uriHelper.company.getCompanyWorkerByIdPath(companyId, userId);

        return await this.webCaller.HttpSend(HttpType.delete, uri);
    }

    public async postPromoteToCompanyAdmin(company: CompanyInfo, user: CompanyWorkerInfo): Promise<GeneralResponse> {
        // @ts-expect-error
        const uri = this.uriHelper.company.getPromoteToCompanyAdminPath(company.id ?? company.companyId, user.id ?? user.userId);

        return await this.webCaller.HttpSend(HttpType.put, uri);
    }

    public async getCompanyHasUserWithRole(id: string, role: string): Promise<ExistsResponse> {
        const uri = this.uriHelper.company.getCompanyHasUserWithRolePath(id, role);

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async postSetCompanyWorkerEnabled(id: string, status: boolean): Promise<GeneralResponse> {
        const uri = this.uriHelper.company.getSetCompanyWorkerEnabledPath(id);
        const requestBody = status;

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    public async postResetExpirationForCompanyWorker(companyId: string, userId: string): Promise<GeneralResponse> {
        const uri = this.uriHelper.company.getResetExpirationForCompanyWorkerPath(companyId, userId);
        const requestBody = false;

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }
}