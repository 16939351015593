<template>
  <div id="errorpage" class="w-full h-full" v-if="privacySettings">
    <div class="container m-auto">
      <div class="flex flex-col h-full items-center">
        <div class="text-left">
          <h1>{{ privacySettings.privacyHeader }}</h1>
          <h3>{{ privacySettings.companyName }}</h3>
          <div>
            {{ privacySettings.privacyText }}
          </div>
        </div>

      </div>
    </div>
  </div>
  <div v-else>
    Daten werden geladen...
  </div>
</template>

<script setup>
import ErrorHandler from "@/utilities/helpers/ErrorHandler";
import {ErrorLocationType} from "@/utilities/enums/Enums";
import {SettingService} from "@/utilities/services/entities/SettingService";
import {onMounted, ref} from "vue";

const settingService = new SettingService();
const privacySettings = ref();

onMounted(async ()=>{
  const privacySettingsResp = await settingService.getPrivacySettings();
  if (privacySettingsResp === null || privacySettingsResp === undefined || privacySettingsResp.statusCode !== 0){
    ErrorHandler.handleError(privacySettingsResp, ErrorLocationType.login);
    return;
  }

  privacySettings.value = privacySettingsResp;
})
</script>