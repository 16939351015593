export class AnAbSystemInfo {
    userId:string = "";
    idCode:string = "";
    name:string = "";
    baseUrl:string = "";
    systemCouplingBaseUrl:string = "";
    systemCouplingName:string = "";
    anabSystemId:string = "";
    phoneNumber:string = "";
    colors:string = "";
    iconName:string = "";
    pdfName:string = "";
    dialCode:string = "";
    textPhoneNumber:string = "";
    voicePhoneNumber:string = "";
    privacyPolicyLink:string = "";
    userConfirmationExpired?:boolean = undefined;
    userLastInteraction?:Date = undefined;
    hasRegistrationGuest:boolean = false;
    companyIdCode:string = "";
    contactPersonName:string = "";
    contactPersonEmail:string = "";
    contactPersonDialCode:string ="";
    contactPersonPhoneNumber:string = "";
}