import { createRouter, createWebHistory  } from 'vue-router'
import LocationReports from "@/views/LocationReports.vue";
import LocationManagement from "@/views/Location.vue";
import RegistrationReports from "@/views/RegistrationReports.vue";
import SafetyInstructions from "@/views/SafetyInstructions.vue";
import Users from "@/views/Users.vue";
import Group2 from "@/views/Group2.vue";
import ControlCenters from "@/views/ControlCenters.vue";
import Login from "@/views/Login.vue";
import Settings from "@/views/Settings.vue";
import Group1 from "@/views/Group1.vue";
import Account from "@/views/Account.vue";
import Notifications from "@/views/Notifications.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import Error from "@/views/Error.vue";
import Logout from "@/views/Logout.vue";
import Privacy from "@/views/Privacy.vue";
import Activities from "@/views/Activities.vue";
import Changelog from "@/views/Changelog.vue";
import CategoryManagement from "@/views/Category.vue";
import Disdi from "@/views/Disdi.vue";
import DisdiMaintenance from "@/views/DisdiMaintenance/DisdiMaintenance.vue";
import OtpLogin from "@/views/DisdiMaintenance/DisdiLogin.vue";
import DisdiExit from "@/views/DisdiMaintenance/DisdiExit.vue";
import Provider from "@/views/Provider.vue";
import ProviderDetails from "@/views/ProviderDetails.vue";
import OAuthDisdiCallback from "@/views/oAuth/OAuthDisdiCallback.vue";
import OAuthDisdiSilentCallback from "@/views/oAuth/OAuthDisdiSilentCallback.vue";
import OAuthSsoCallback from "@/views/oAuth/OAuthSsoCallback.vue";
import OAuthSsoSilentCallback from "@/views/oAuth/OAuthSsoSilentCallback.vue";
import SamlCallback from "@/views/oAuth/SamlCallback.vue";
import checkToken from "@/router/middleware/checkToken";
import redirectToLogin from "@/router/middleware/redirectToLogin";
import checkPermissionForRoute from "@/router/middleware/checkPermission";
import closeModals from "@/router/middleware/closeModals";
import hideError from "@/router/middleware/hideError";
import resetFooterCounter from "@/router/middleware/resetFooterCounter";
import changeTitle from "@/router/middleware/changeTitle";
import checkValidParams from "@/router/middleware/checkValidParams";

const oAuthRoutes = [
  {
    path: "/disdi/oAuthCallback",
    name: 'DisdioAuthCallback',
    component: OAuthDisdiCallback,
  },
  {
    path: "/disdi/oAuthCallback/silent",
    name: 'DisdioAuthSilentCallback',
    component: OAuthDisdiSilentCallback,
  },
  {
    path: "/sso/oAuthCallback",
    name: 'SsoOAuthCallback',
    component: OAuthSsoCallback,
  },
  {
    path: "/sso/oAuthCallback/silent",
    name: 'SsoOAuthSilentCallback',
    component: OAuthSsoSilentCallback,
  },
  {
    path: "/saml/tokenCallback",
    name: 'SamlCallback',
    component: SamlCallback,
  },
]

const routes = [
  {
    path: '/',
    name: 'Home',
    component: PageNotFound, // THIS IS ONLY A PLACEHOLDER
    meta: {
      middleware: [redirectToLogin],
    }
  },
  ...oAuthRoutes,
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      middleware: [hideError, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/disdi/login/:id([0-9a-f]{8}[-]?[0-9a-f]{4}[-]?[0-9a-f]{4}[-]?[0-9a-f]{4}[-]?[0-9a-f]{12})',
    name: 'DisdiOtpLogin',
    component: OtpLogin,
    meta: {
      middleware: [hideError, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/disdi/maintain',
    name: 'DisdiMaintenance',
    component: DisdiMaintenance,
    meta: {
      middleware: [hideError, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/disdi/exit',
    name: 'DisdiExit',
    component: DisdiExit,
    meta: {
      middleware: [hideError, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/disdi/:type/:id',
    name: 'DisdiView',
    components: {
      default: Disdi,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [checkPermissionForRoute, hideError, checkValidParams, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/locationRegistration',
    name: 'LocationRegistration',
    components: {
      default: LocationReports,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [checkPermissionForRoute, hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/locationManagement',
    name: 'LocationManagement',
    components: {
      default: LocationManagement,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [checkPermissionForRoute, hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/registrationReports',
    name: 'RegistrationReports',
    components: {
      default: RegistrationReports,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [checkPermissionForRoute, hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/safetyInstructions',
    name: 'SafetyInstructions',
    components: {
      default: SafetyInstructions,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [checkPermissionForRoute, hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/activity',
    name: 'Activities',
    components: {
      default: Activities,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [checkPermissionForRoute, hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/category',
    name: 'CategoryManagement',
    components: {
      default: CategoryManagement,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [checkPermissionForRoute, hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/providermanagement/:id([0-9a-f]{8}[-]?[0-9a-f]{4}[-]?[0-9a-f]{4}[-]?[0-9a-f]{4}[-]?[0-9a-f]{12})',
    name: 'ProviderManagementDetails',
    components: {
      default: ProviderDetails,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [checkPermissionForRoute, hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/providermanagement',
    name: 'ProviderManagement',
    components: {
      default: Provider,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [checkPermissionForRoute, hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/group2',
    name: 'Group2',
    components: {
      default: Group2,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [checkPermissionForRoute, hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/users',
    name: 'User',
    components: {
      default: Users,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [checkPermissionForRoute, hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/controlCenter',
    name: 'ControlCenter',
    components: {
      default: ControlCenters,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [checkPermissionForRoute, hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    components: {
      default: Settings,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [checkPermissionForRoute, hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/group1',
    name: 'Group1',
    components: {
      default: Group1,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [checkPermissionForRoute, hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/account',
    name: 'Account',
    components: {
      default: Account,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/changelog',
    name: 'Changelog',
    components: {
      default: Changelog,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    components: {
      default: Notifications,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [hideError, checkToken, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: '/error',
    name: 'Error',
    components: {
      default: Error,
      LeftSidebar: () => import('@/components/Sidebar.vue'),
    },
    meta: {
      middleware: [hideError, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: "/logout",
    name: 'Logout',
    component:  Logout,
    meta: {
      middleware: [hideError, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: "/privacy",
    name: 'Privacy',
    component:  Privacy,
    meta: {
      middleware: [hideError, closeModals, resetFooterCounter, changeTitle],
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component:  PageNotFound,
    meta: {
      middleware: [hideError, closeModals, resetFooterCounter, changeTitle],
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})


/*--- MIDDLEWARE IMPLEMENTATION ---*/


function middlewarePipeline (context:any, middleware:any, index:any) {
  const nextMiddleware = middleware[index]

  if(!nextMiddleware){
    return context.next
  }

  return () => {
    const nextPipeline = middlewarePipeline(
        context, middleware, index + 1
    )

    nextMiddleware({ ...context, next: nextPipeline })

  }
}

router.beforeEach((to, from, next) => {
  /* Navigate to next if middleware is not applied */
  if (!to.meta.middleware) {
    return next()
  }

  const middleware:any = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    //   store  | You can also pass store as an argument
  }

  return middleware[0]({
    ...context,
    next:middlewarePipeline(context, middleware,1)
  })
})

export default router;
