// collect all identifiers and their according methods in an array to register incoming methods [Back to Front]

import {HubNotification} from "@/models/HubNotification";
import {useGlobalStore} from "@/stores/GlobalStore";
import {notify} from "@kyvg/vue3-notification";
import router from "@/router";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";
import {ErrorLocationType, TableType} from "@/utilities/enums/Enums";
import FilterService from "@/utilities/services/FilterService";
import {RegistrationService} from "@/utilities/services/entities/RegistrationService";
import {permissionColumn} from "@/AppConfig";
import NullOrUndefinedHelper from "@/utilities/helpers/NullOrUndefinedHelper";
import {Guid} from "guid-typescript";

export default [
    {
        key:"refreshReportTableData",
        method:async ()=>{
            const globalStore = useGlobalStore();

            if (!globalStore.refreshIsActive) return;

            const regService = new RegistrationService();

            let resp = null;
            let convertedData = null;
            switch (router.currentRoute.value.name) {
                case "LocationRegistration":
                    console.log("refreshing locationReports, triggered by SignalR");
                    globalStore.gridDataIsLoading = true;
                    resp = await regService.getLocationReports();
                    if (resp === null || resp === undefined || resp.statusCode !== 0) {
                        ErrorHandler.handleError(resp, ErrorLocationType.table);
                        return;
                    }

                    globalStore.gridStore?.removeAll();
                    //@ts-expect-error
                    globalStore.gridStore?.setStoreData(resp.locationRegistrationInfo);

                    // apply filters
                    globalStore.gridStore?.filter({
                        id: "userFilter",
                        filterBy: FilterService.buildFilter(TableType[TableType.registerLocation])
                    });

                    // apply hidden cols
                    // @ts-expect-error
                    for (const c of globalStore.gridColumnStore?.storage.values) {
                        const needsHiding = globalStore.userPreferences.userHiddenColumns.some((thc) => thc.attributeName === c.data.field && thc.tableName === TableType[TableType.registerLocation]);

                        if(needsHiding && !c.hidden) {
                            c.hide();
                        }
                        else if(!needsHiding && c.hidden && !permissionColumn.includes(c.id)) {
                            c.show();
                        }
                    }

                    globalStore.gridDataIsLoading = false;
                    console.log("done refreshing locationReports, triggered by SignalR");
                    break;
                case "RegistrationReports":
                    console.log("refreshing registrationReports, triggered by SignalR");
                    globalStore.gridDataIsLoading = true;
                    resp = await regService.getRegistrationReports();
                    if (resp === null || resp === undefined || resp.statusCode !== 0) {
                        ErrorHandler.handleError(resp, ErrorLocationType.table);
                        return;
                    }

                    //convert data to specific properties to make grid able to sort the data
                    convertedData = resp.registrationInfo.map(r => {
                        //@ts-expect-error
                        r.lastRegisteredLocationDate = r.registeredLocationInfo[0].lastRegisteredLocationDate;
                        //@ts-expect-error
                        r.lastUnRegisteredLocationDate = r.registeredLocationInfo[0].lastUnRegisteredLocationDate;

                        if(NullOrUndefinedHelper(r.id)) r.id = Guid.create().toString();

                        return r
                    })

                    globalStore.gridStore?.removeAll();
                    //@ts-expect-error
                    globalStore.gridStore?.setStoreData(convertedData);

                    // apply filters
                    globalStore.gridStore?.filter({
                        id: "userFilter",
                        filterBy: FilterService.buildFilter(TableType[TableType.register])
                    });

                    // apply hidden cols
                    // @ts-expect-error
                    for (const c of globalStore.gridColumnStore?.storage.values) {
                        const needsHiding = globalStore.userPreferences.userHiddenColumns.some((thc) => thc.attributeName === c.data.field && thc.tableName === TableType[TableType.register]);

                        if(needsHiding && !c.hidden) {
                            c.hide();
                        }
                        else if(!needsHiding && c.hidden && !permissionColumn.includes(c.id)) {
                            c.show();
                        }
                    }

                    globalStore.gridDataIsLoading = false;
                    console.log("done refreshing registrationReports, triggered by SignalR");
                    break;
                default:
                    console.log("refreshing Table via SignalR not neccessary for ", router.currentRoute.value.name);
                    return;
            }
        }
    },
    {
        key:"addNotification",
        method:(notif:HubNotification)=>{
            const globalStore = useGlobalStore();
            globalStore.notifications.push(notif);

            if (router.currentRoute.value.name === "Notifications"){
                // @ts-expect-error
                globalStore.gridStore.setStoreData(globalStore.notifications);
            }

            notify({
                title: "Benachrichtigung",
                text: notif.text,
                duration: globalStore.settings.notifications.durationInfo,
                type: "info"
            });
        }
    },
    {
        key:"removeNotification",
        method:(id:number)=>{
            const globalStore = useGlobalStore();
            globalStore.notifications = globalStore.notifications.filter((n:HubNotification)=> n.id !== id);

            if (router.currentRoute.value.name === "Notifications"){
                // @ts-expect-error
                globalStore.gridStore.setStoreData(globalStore.notifications);
            }
        }
    },
];
