import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {
    GeneralResponse,
    SelectableRoleResponse,
    UserIdNumberResponse,
    UserLocationPermissionResponse, UserResponse,
    UsersResponse
} from "@/models/api/Responses";
import {ErrorLocationType, HttpType, PreferenceType} from "@/utilities/enums/Enums";
import {UserPreference} from "@/models/user/UserPreference";
import {UserPreferenceRequest, UserRequest} from "@/models/api/Requests";
import {UserInfo} from "@/models/user/UserInfo";
import {SelectableRoleInfo} from "@/models/SelectableRoleInfo";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";

export class UserService {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }

    public async getUsers(viewtype: string = "display"): Promise<UsersResponse> {
        const uri = this.uriHelper.users.getUsersPath() + `/viewtype/${viewtype}`;

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    /*
        when clearAll === true: either ALL preferences are cleared or JUST preferences of the specified table (tableName === "" or tableName === <smth>)
    */
    public async setUserPreferences(id: string, typeId: PreferenceType, tableName: string, clearAll: boolean, prefList: Array<UserPreference>) {
        const uri = this.uriHelper.preferences.getSaveUserPreferencesPath();
        const requestBody = new UserPreferenceRequest(id, typeId, tableName, clearAll, prefList);

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    public async getUserById(id: string, errorLocation:ErrorLocationType): Promise<UserInfo|null> {
        const uri = this.uriHelper.users.getUserByIdPath(id);

        const resp = await this.webCaller.HttpSend(HttpType.get, uri) as UserResponse;
        if (resp === null || resp === undefined || resp.statusCode !== 0){
            ErrorHandler.handleError(resp as GeneralResponse, errorLocation);
            return null;
        }

        return resp.userInfo;
    }

    public async getUserRoles(errorLocation:ErrorLocationType): Promise<Array<SelectableRoleInfo>|null> {
        const uri = this.uriHelper.users.getUsersRoleSelectablePath();

        const roleResp = await this.webCaller.HttpSend(HttpType.get, uri) as SelectableRoleResponse;
        if (roleResp === null || roleResp === undefined || roleResp.statusCode !== 0){
            ErrorHandler.handleError(roleResp, errorLocation);
            return null;
        }

        return roleResp.roleInfo;
    }

    public async getUserPreferences(id: string, typeId: PreferenceType) {
        const uri = this.uriHelper.preferences.getUserPreferencePath(id, typeId);

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async addOrUpdateUsers(user: UserInfo): Promise<any> {
        const uri = user.id === ""
            ? this.uriHelper.users.getUsersPath()
            : this.uriHelper.users.getUserByIdPath(user.id);

        const requestBody = new UserRequest(user);

        const reqType = user.id === ""
            ? HttpType.post
            : HttpType.put;

        return await this.webCaller.HttpSend(reqType, uri, requestBody);
    }

    public async deleteUser(id: string): Promise<any> {
        const uri = this.uriHelper.users.getUserByIdPath(id);

        return await this.webCaller.HttpSend(HttpType.delete, uri);
    }

    public async getUserLocationPermission(id: string): Promise<UserLocationPermissionResponse> {
        const uri = this.uriHelper.users.getUserLocationPermissionPath(id);

        return await this.webCaller.HttpSend(HttpType.delete, uri);
    }

    public async setUserLocationPermission(id: string, authorise: boolean, locationIds: Array<number>): Promise<GeneralResponse> {
        const uri = this.uriHelper.users.getUserLocationPermissionPath(id, authorise);
        const requestBody = locationIds;

        return await this.webCaller.HttpSend(HttpType.put, uri, requestBody);
    }

    public async resetUserActivity(userIds: Array<string>): Promise<GeneralResponse> {
        const uri = this.uriHelper.users.getResetUserActivityPath();
        const requestBody = userIds;

        return await this.webCaller.HttpSend(HttpType.put, uri, requestBody);
    }

    public async getNextFreeUserId(): Promise<UserIdNumberResponse> {
        const uri = this.uriHelper.users.getNextFreeUserIdPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }
}