<template>
	<div>
		<div class="block w-full h-60">

			<div class="grid grid-cols-2 gap-4 w-full">
				<div class="">

					<div class="inputTitle">
						{{ t('popups.activity.name') }}
					</div>
					<div class="inputField flex-grow">
						<input type="text" class="w-full" v-model="popupData.name">
					</div>
				</div>

        <div class="">
          <div class="inputTitle">
            {{ t('popups.activity.category') }}
          </div>
          <div class="inputField flex-grow">
            <Select :selected="popupData.categoryId" :options="categoryList" mode="single" @selectChanged="changeCategoryId" />
          </div>
        </div>

			</div>

		</div>
	</div>
</template>

<script setup>
import {defineProps, onMounted, ref, toRef} from "vue";
import {useI18n} from "vue-i18n";
import {ErrorLocationType} from "@/utilities/enums/Enums";
import {CategoryService} from "@/utilities/services/entities/CategoryService";
import Select from "@/components/inputs/Select";


const props = defineProps({
  data: Object
})
const popupData = toRef(props, 'data');

const { t } = useI18n();
const categoryService = new CategoryService();

const categoryList = ref([]);

const changeCategoryId = (value)=>{
  popupData.value.categoryId = value !== null ? value : "";
}

onMounted(async ()=>{
  categoryList.value = (await categoryService.getCategories("display", ErrorLocationType.popup))?.map((c) =>{
    c.name = c.title;
    return c;
  });
})

</script>

<style scoped>

</style>
