export default {
  "sidebar": {
    "LocationRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location reports"])},
    "LocationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
    "RegistrationReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration reports"])},
    "SafetyInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety instructions"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "ControlCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control center"])},
    "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "Group2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type/Levels"])},
    "Group1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netcenter/Regions"])},
    "Activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
    "ProviderManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providers"])},
    "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "CategoryManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])}
  },
  "footer": {
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog"])}
  },
  "login": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The smart<br/>Online-Managment <br/>of 1ANAB Web"])},
    "subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1ANAB WEB"])},
    "tabs": {
      "login": {
        "tabTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail*"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password*"])},
        "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
      },
      "changePassword": {
        "tabTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which e-mail do you want to change the password for?"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail*"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
        "confirmHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail was send"])},
        "confirmDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were send an e-mail with a link to reset your password. Please check your inbox."])},
        "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
        "newPasswordHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password*"])},
        "newPasswordDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the new password.<br/><br/>The following rules need to be met:<br/>- mind. 8 Zeichen<br/>- minimum 1 none alpha numeric character<br/>- minimum 1 number (0-9)<br/>- minimum 1 uppercase letter<br/>- minimum 1 lowercase letter"])},
        "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "newPasswordRepeatLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
        "newPasswordSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "newPasswordCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
      }
    },
    "error": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That sadly did not work."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while processing your request. Please try again later or return to the login."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back to login"])}
    },
    "notifySuccessfulLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your login was successful."])}
  },
  "404": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wooops, error 404."])},
    "subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong there."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested page was not found.<br/>Please try again later or navigate to the home page.<br/>"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to home page"])},
    "buttonLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to login"])}
  },
  "403": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wooops, error 403."])},
    "subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No permission"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You sadly do not have permission to use this function.<br/>Please contact your supervisor.<br/>"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to home page"])}
  },
  "logout": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout successful"])},
    "subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See you soon!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<placeholder>"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to login"])}
  },
  "changelog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog"])}
  },
  "settings": {
    "languageSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update language"])},
    "startPageSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update home page"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "tabs": {
      "systemSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System settings"])},
      "speachTexts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice settings"])},
      "coupledSystems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupled systems"])},
      "providerSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider settings"])},
      "disdiSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISDI Settings"])}
    },
    "providerSettings": {
      "elapseTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elapsed time in days"])},
      "infoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infotext"])},
      "inactiveUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive users"])}
    },
    "systemSettings": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])}
    },
    "coupledSystems": {
      "testSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test successful"])},
      "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the coupled system '", _interpolate(_named("name")), "'?"])},
      "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the ", _interpolate(_named("count")), " selected coupled systems?"])},
      "grid": {
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "loginName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loginname"])},
        "testCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test coupling"])},
        "testingCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait..."])}
      }
    },
    "disdiSettings": {
      "activityFeature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Feature"])},
      "editDefaultActvity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defaultvalue for Activity can be changed (after sign on)"])},
      "disdi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISDI Feature"])},
      "readRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Who is allowed to:) read the Digital Station Diary"])},
      "editRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Who is allowed to:) activate DISDI for locations"])},
      "picturesAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of pictures per DISDI-Entry"])},
      "metaTimestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata: Timestamp"])},
      "metaEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata: Persistance of originator"])},
      "metaInvisibleTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata: Times-Visibilityperiod"])},
      "metaInvisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata: Metadata-Visibilityperiod"])},
      "metaDeleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata: Metadata-Expirationtime (deletion of metadata)"])},
      "showInAppNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App: Count of DISDI-Entries that get send to the app"])},
      "showInAppDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App: Maximum age of DISDI-Entries that get send to the app"])},
      "useGalleryInApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App: Should pictures form the user be able to add existing pictures to a Disdi-Entry?"])},
      "metaTimestampType": {
        "None": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "OnlyRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only sign on"])},
        "OnlyUnregistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only sign off"])},
        "Both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign on and off"])}
      },
      "disdiType": {
        "NoDISDI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Off"])},
        "SomeLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single locations"])},
        "AllLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All locations"])}
      }
    },
    "grid": {
      "intern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Identifier"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "speachText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice text"])}
    }
  },
  "validators": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])},
    "requiredIf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of this fields is required."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field must be a valid e-mail adress."])},
    "sameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These fields are identical."])},
    "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitude needs to be between -180 an 180."])},
    "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitude needs to be between -90 an 90."])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid phone number. Valid format: +491234567891"])},
    "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field only allows digits."])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This field is only allowed ", _interpolate(_named("amount")), " charaters maximum."])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This field needs to have atleas ", _interpolate(_named("amount")), " charaters."])},
    "hasNoneAlphaNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimum 1 none alpha numeric character is required"])},
    "hasNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimum 1 number is required"])},
    "hasUppercaseLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimum 1 uppercase letter is required"])},
    "hasLowercaseLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimum 1 lowercase letter is required"])},
    "float": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No valid float ('.' as seperator) recognized. (e.g. 0.1)"])}
  },
  "notifications": {
    "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You password was reset successfully!"])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "deleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message text"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "showDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show details"])},
    "applySafetyInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete notification"])},
    "yesButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YES"])},
    "noButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete the selected notification?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the ", _interpolate(_named("count")), " selected notifications?"])},
    "promptInformationTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For '", _interpolate(_named("locationNames")), "' the following coupled Safetyinstruction was added:"])},
    "promptInformationBody": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Name: ", _interpolate(_named("name")), " ; Text: ", _interpolate(_named("text"))])},
    "promptInformationHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can edit the Safetyinstruction either in the Location-Details or via the Safetyinstruction-managment"])},
    "signalrDisconnected": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAUTION"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The automatic refresh lost connection, please reload the page."])}
    }
  },
  "account": {
    "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password was changed successfully!"])},
    "passwordResetButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "lastPasswordChangeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password was last changed on"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat new password"])},
    "passwordNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "passwordCurrent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "passwordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "browserLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser Language"])},
    "startpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home page"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company internal identification"])},
    "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])}
  },
  "global": {
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "exportCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export CSV"])},
    "importCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import CSV"])},
    "downloadPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])},
    "downloadPdfMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send PDF via E-Mail"])},
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
    "unregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign off"])},
    "noValidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your session was expired or faulty, please log in again!"])},
    "validationFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in the fields correctly."])},
    "units": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in days"])}
    },
    "emptyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No entries"])}
  },
  "locations": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
    "newLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New location"])},
    "massDisdi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change DISDI setting for multiple locations"])},
    "exportFilename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location export"])},
    "changeCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit coupling"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the following location '", _interpolate(_named("name")), "'?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the ", _interpolate(_named("count")), " selected locations?"])},
    "grid": {
      "secId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "group2Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type/Level"])},
      "group1Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netcenter/Regions"])},
      "customAdditionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical place"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "controlCenterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control center"])},
      "noCoupledSystems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This location has no coupled systems"])}
    },
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location(s) deleted!"])},
    "willDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download startet"])},
    "willBeSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail was send"])}
  },
  "safetyInstructions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety instructions"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New safety instruction"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit safety instruction"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the safety instruction '", _interpolate(_named("name")), "'?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the ", _interpolate(_named("count")), " seleted safety instructions?"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "safetyInstructionLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
    "selectedLocationsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selected locations"])},
    "csvDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CSV"])},
    "csvDownloadFilename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety instructions"])},
    "grid": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
    },
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety instruction(s) deleted!"])}
  },
  "providerManagement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider management"])},
    "grid": {
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adress"])},
      "showStaff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show staff member"])},
      "companyIdCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification number"])}
    },
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New provider"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the provider '", _interpolate(_named("name")), "'?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the ", _interpolate(_named("count")), " selected providers?"])},
    "inactiveUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive Users"])}
  },
  "providerManagementDetails": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff members"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New staff member"])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff members summary"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adress"])},
    "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person"])},
    "lessInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["less provider details"])},
    "moreInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more provider details"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "grid": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activ"])},
      "cleared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabling"])},
      "reactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reactivate"])}
    },
    "connectedWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing staff member was connected with your system."])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the staff member '", _interpolate(_named("name")), "'?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the ", _interpolate(_named("count")), " selected staff members?"])}
  },
  "locationsReports": {
    "secondaryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locationreports"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "newLocationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual sign on/off"])},
    "newLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual sign on/off"])},
    "instantMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instant message"])},
    "instantMessageDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With this you are sending an instant message to all signed on users. \nYou can edit the message to send in the text field below."])},
    "registerPopup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual sign on/off"])},
    "locationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location details"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street/Nr."])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip/City"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "gps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPS-Coordinates"])},
    "securityInformations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety instructions"])},
    "detailsHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "noGpsData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No coordinates"])},
    "tabs": {
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User history"])},
      "locationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location details"])},
      "safetyInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safteyinstructions"])},
      "safetyInstructionsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no safteyinstructions for this location."])}
    },
    "grid": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last sign on/off"])},
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed on"])},
      "unregistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed off"])},
      "coupled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupled"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adress"])},
      "group1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netcenter/Region"])},
      "group2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type/Level"])},
      "controlCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control center"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "personCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])}
    }
  },
  "registrationReports": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register reports"])},
    "userHistoryPopup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User history"])},
    "userDetailPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User details"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "grid": {
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "locationId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location ID"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign on"])},
      "unregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign off"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "guestCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest count"])},
      "kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company internal identification"])},
      "coupledUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupled user"])}
    },
    "confirmUnregister": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to sign off '", _interpolate(_named("name")), "' at '", _interpolate(_named("location")), "'?"])}
  },
  "users": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New user"])},
    "exportCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export CSV"])},
    "csvDownloadFilename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the user '", _interpolate(_named("name")), "'?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the ", _interpolate(_named("count")), " selected users?"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User(s) deleted"])},
    "resetActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to reset the user activity?"])},
    "resetMultipleActivity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to reset the activity for ", _interpolate(_named("count")), " users?"])},
    "inactiveUsersReactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected inactive users have been reactivated"])},
    "grid": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "identificationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification number"])},
      "kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company internal identification"])},
      "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activ"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "activeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User activ"])},
      "inactiveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User inactiv, will be deleted soon"])}
    },
    "menu": {
      "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change permission"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset activity status"])}
    }
  },
  "regions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netcenter/Region"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Netcenter/region"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the Netcenter/region '", _interpolate(_named("name")), "'?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the ", _interpolate(_named("count")), " selected Netcenters/regions?"])},
    "grid": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "syncId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SyncId"])}
    },
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netcenter/Region deleted"])}
  },
  "activity": {
    "grid": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])}
    },
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the activity '", _interpolate(_named("name")), "'?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the ", _interpolate(_named("count")), " selected activities?"])}
  },
  "activitySet": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity set"])},
    "tabs": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity sets"])},
      "manageActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage activities"])}
    },
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New activity set"])},
    "grid": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])}
    },
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the activity set '", _interpolate(_named("name")), "'?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the ", _interpolate(_named("count")), " selected activity sets?"])}
  },
  "controlCenters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control centers"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the control center '", _interpolate(_named("name")), "'?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the ", _interpolate(_named("count")), " selected control centers?"])},
    "newcontrolCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New control center"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "syncId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SyncId"])},
    "grid": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "syncId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SyncId"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])}
    },
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control center(s) deleted"])}
  },
  "levels": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type/Level"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types/Levels deleted"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New type/level"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change type/level"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the type/level '", _interpolate(_named("name")), "'?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the ", _interpolate(_named("count")), " selected types/levels?"])},
    "grid": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])}
    }
  },
  "category": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories deleted"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the following category '", _interpolate(_named("name")), "'?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to delete the ", _interpolate(_named("count")), " selected categories?"])},
    "grid": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "unicode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])}
    }
  },
  "disdi": {
    "view": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Station Diary"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "grid": {
        "diaryDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "categoryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
        "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachemnt"])},
        "createDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creationdate"])},
        "registrationStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign on"])},
        "registrationEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign off"])},
        "userFullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "userPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phonenumber"])},
        "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
        "categoryEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category not found"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
      },
      "csvExportFileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISDI-Entries"])},
      "pdfDownloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF was downloaded successfully"])}
    },
    "login": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait and get your mobile ready, you will be transfered to the login automatically."])}
    },
    "maintenance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Station Diary maintenance"])},
      "diaryDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
      "attachmentsPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachmentpreview"])},
      "disdiInfo": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
        "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign on/off"])}
      },
      "saveAndClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close entry"])},
      "uploadDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HINT: As soon as pictured get selected they automatically will be uploaded. Pictures with the same name will be overwritten."])},
      "uploadedFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last uploaded pictures"])},
      "generalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Information"])},
      "maintainInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information to maintain"])},
      "uploadButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select attachments"])}
    },
    "exit": {
      "byeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entry was closed."])},
      "byeSubText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you and until next time :)"])}
    }
  },
  "otpLogin": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
  },
  "error": {
    "status": {
      "general": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An error occured. Server responded with: '", _interpolate(_named("message")), "'"])},
      "accessDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured. The request was denied."])},
      "-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Request"])},
      "1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An internal server error occured, please try again later or contact the support."])},
      "1001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested data does not exist. Please check the code for correctness and try again."])},
      "1002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Validation Error"])},
      "1003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No System is attached to the User"])},
      "1004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No locations"])},
      "1005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Validation Error"])},
      "1006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code does not exist"])},
      "3000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AnAbSystemId ValidationError"])},
      "3001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CompanyId ValidationError"])},
      "3002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AnAbSystem does not exist"])},
      "3003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company does not exist"])},
      "3004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitficationnumber already in use"])},
      "3005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AnAbSystemCompany does not exist"])},
      "3006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid user"])},
      "3007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Companyworker is part of another provider"])},
      "3008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double CompanyWorkerAnAbSystem"])},
      "3009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["InstallationId ValidationError"])},
      "3010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Identitficationnumber is invlaid."])},
      "3011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double AnAbAppInstallation"])},
      "3012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user still has a valid connection to another 1ANAB System. Please delete this connection before retrying."])},
      "3013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user is set as the contact person for this company. Please appoint another contact person, before deleting the user."])},
      "3014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user is set as the contact person for a 1ANAB System. Please appoint another contact person, before deleting the user."])},
      "3016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correlation to 1ANAB System still present."])},
      "3017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This text could not be found."])},
      "3018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AnAbSystem already exists in AnAbSystemDescription"])},
      "3019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An existing system correlation is preventing this action."])},
      "3020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AnAbSystem already exists in CompanyWorkerAnAbSystem"])},
      "3021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PageDescription already exists in AnAbSystemDescription"])},
      "3022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PageDescriptionId ValidationError"])},
      "3023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User exists and already has a CompanyId"])},
      "3024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Name hat ein ungültiges Format."])},
      "3025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Postleitzahl hat ein ungültiges Format."])},
      "3026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Stadt hat ein ungültiges Format."])},
      "3027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Straße hat ein ungültiges Format."])},
      "3028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Hausnummer hat ein ungültiges Format."])},
      "3029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The length of the ANABSystemCompanyCode does not have 11 characters."])},
      "3030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user already exists in another system."])},
      "3031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Persistenz-Eingabewert ist ungültig.Der eingegebene Wert muss kleiner als 365 sein."])},
      "3032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LastInteraction ValidationError"])},
      "4000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User does not exist"])},
      "4001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UserId ValidationError"])},
      "4002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile number ValidationError"])},
      "4003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password ValidationError"])},
      "4004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RoleName ValidationError"])},
      "4005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User has no fitting role"])},
      "4006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than one user use this phon number"])},
      "4007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected phone number cannot recieve SMS"])},
      "4008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected phone number is invalid"])},
      "4009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username ValidationError"])},
      "4010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username is already registered"])},
      "4011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phonenumber is already registered"])},
      "4012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your user roles are git."])},
      "4013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["County code ValidationError"])},
      "4014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GSM-Number already taken."])},
      "4015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error occured in user history"])},
      "4016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User just has 1ANAB as product"])},
      "4017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no admin user"])},
      "4018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid link. Please contact an admin."])},
      "4019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished successfully. You gained the admin role."])},
      "4020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User does not have product"])},
      "4021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role does not have product"])},
      "4022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username already taken"])},
      "4023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User cannot edit"])},
      "4024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UserAdditional is Null"])},
      "4025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UserAdditional ValidationError"])},
      "4026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User with this e-mail adress already exists."])},
      "4028": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This user already exists in another company (", _interpolate(_named("companyName")), ")"])},
      "5000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while requesting systems for user."])},
      "5009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum number of locations for your licence has been reached."])},
      "6000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No open registration found."])},
      "6001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location ID invalid"])},
      "6002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System could not be found"])},
      "6003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faulty GPS-coordinates"])},
      "6666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General error occured while signing on/off"])},
      "6667": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration was not recognized"])},
      "6969": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User already signed on at this location."])},
      "7000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification could not be send."])},
      "7001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User could not be found"])},
      "7002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many recipients were selected (max 20)"])},
      "9000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please only specify 5-digit long identification numbers"])},
      "9001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location id already exist, please specify a different one."])},
      "9002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location does not exist"])},
      "9003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location cannot be deleted with active open registrations."])},
      "9004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync id already exist, please specify a different one"])},
      "9005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupled system does not exist"])},
      "9006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid location"])},
      "9007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid coupled system"])},
      "9008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid System-Coupling-Identifier"])},
      "9009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid couple system's name"])},
      "9010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection already exists."])},
      "9011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupled System does not exist"])},
      "9012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
      "9013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text is required"])},
      "9014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Identifier ValidationError"])},
      "9015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreign Location Identifier ValidationError"])},
      "9016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Id"])},
      "9017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location <> safety instruction connection does not exist"])},
      "9018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety instruction does not exist"])},
      "9019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type/Level does not exist"])},
      "9020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are still locations associated with this data. Please remove the association before deletion."])},
      "9021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control center does not exist"])},
      "9022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are still locations associated with this data. Please remove the association before deletion."])},
      "9023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netcenter/Region does not exist"])},
      "9024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are still locations associated with this data. Please remove the association before deletion."])},
      "9025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration does not exist"])},
      "9026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day is required"])},
      "9027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is not enabled for this system."])},
      "9028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User already signed on."])},
      "9029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register System Identifier ValidationError"])},
      "9030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date is required"])},
      "9031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupled Location Registration does not exist"])},
      "9032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS on blacklist"])},
      "9033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid RegistrationId"])},
      "9034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not permitted ad this location."])},
      "9035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is signed on. Please first sign off."])},
      "9036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User already signed on at this location"])},
      "9037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User already signed off at this location"])},
      "9038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client is required"])},
      "9039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid PreferenceType"])},
      "9040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table name is required"])},
      "9041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PrefernceList is empty"])},
      "9042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribute name is required"])},
      "9043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail is required"])},
      "9044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required"])},
      "9045": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify youe e-mail adress."])},
      "9046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to change your password (every 90 days)."])},
      "9047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name is required"])},
      "9048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification number is required"])},
      "9049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not allowed to change the admin role."])},
      "9050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletion failed. User still has registrations."])},
      "9051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default location permission does not exist"])},
      "9052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Users"])},
      "9053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Settings name"])},
      "9054": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid settings value"])},
      "9055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting does not exist"])},
      "9056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL is required"])},
      "9057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username is required"])},
      "9058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact phone number is required"])},
      "9059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System already connected"])},
      "9060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elapsed notice is required"])},
      "9061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elapsed time is required"])},
      "9062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Template is required"])},
      "9063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token is required"])},
      "9064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeate password is required"])},
      "9065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords dont match."])},
      "9066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AccessToken is required"])},
      "9067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RefreshToken is required"])},
      "9068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid RefreshToken"])},
      "9069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Token"])},
      "9070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination of e-mail and password doess not exist."])},
      "9071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password was faulty too many times. User got locked out."])},
      "9072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot show SSO result."])},
      "9073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error occured while using OpenId."])},
      "9074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User could not be logged in via OpenId."])},
      "9075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No identification number could be generated."])},
      "9076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User already exists"])},
      "9077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is deactivated."])},
      "9078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AnAbSystem does not exist in CompanyWorkerAnAbSystem"])},
      "9079": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no digit"])},
      "9080": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password is required"])},
      "9081": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password is required"])},
      "9082": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New and old password are the same, please change to a different password."])},
      "9083": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password was wrong"])},
      "9084": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are using openId"])},
      "9089": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail and phone number required"])},
      "9100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO login failed. No authorization could be found."])},
      "9101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot delete yourself."])},
      "9104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The identificationnumer is already taken"])},
      "9105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The format of the entered identificationnumber is wrong."])},
      "9106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecting to the coupled System failed."])},
      "9107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The activity-name must be unique"])},
      "10000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DiaryEntry Not Found"])},
      "10001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DiaryEntryAttachment Not Found"])},
      "10002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISDI is disabled"])},
      "10003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISDI has no pictures configured"])},
      "10004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request does not match with config of System"])},
      "10005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DiaryEntryId is not Valid"])},
      "10008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DiaryEntry is not completed"])},
      "10009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MetaTimestamp Error"])},
      "10010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF template file not found"])},
      "11001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picture already deleted or could not be found."])},
      "12002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The category is still used by an activity."])},
      "999999": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload failed, please try again later."])},
      "666666666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boolean value needs to be either 'True'/'true' or 'False'/'false'"])},
      "666666666666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No connection could be made to the server, please check your internet connetivity"])}
    }
  },
  "popups": {
    "settings": {
      "spokenText": {
        "intern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal identification"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "spokenText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voive text"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change voice text"])}
      },
      "systemSettings": {
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change system setting"])},
        "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
      },
      "coupledSystems": {
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit coupled system"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couple new system"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System name"])},
        "baseUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base URL"])},
        "partnerSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person"])},
        "nameOfPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name of contact person"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "loginData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login data"])},
        "loginName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login name"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login data for foreign systems"])},
        "resetPasswordText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please send us a ticket. We will send the credentials as soon as possible."])},
        "resetPasswordHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])}
      }
    },
    "registrationReports": {
      "grid": {
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
        "registerUnregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign on"])},
        "unregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign off"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
        "guestCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests"])}
      },
      "changeActivity": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change default activity"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an activity with the dropdown or input a new one into the input-field."])},
        "currentActivityTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current activity:"])},
        "takeGivenActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select existing activity:"])},
        "createNewActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new activity:"])}
      }
    },
    "providerManagementDetails": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New staff member"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit staff member"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "dialCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "workerData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff Details"])},
      "enabling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabling"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity status"])},
      "inviteAsAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite as Admin"])},
      "invitationInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Staff member was invited on ", _interpolate(_named("dateString")), "."])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "companyHasAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New staff members can only be created by an admin, please contact:"])}
    },
    "providerManagement": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit provider"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New provider"])},
      "gsmSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GSM-Search"])},
      "similarCompanies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Similar Provider found"])},
      "selectedSimilarProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected provider:"])},
      "editInsertedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit supplied data"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider name"])},
      "kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification number"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
      "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZIP"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "houseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House number"])},
      "phonePrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact: Country code"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact: Phone number"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
      "providerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of known providers"])},
      "providerSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider search"])},
      "chooseAvailableProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected existing provider"])}
    },
    "locations": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
      "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZIP"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])}
    },
    "locationInfo": {
      "section1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDs & GPS"])},
      "section2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name & Adress"])},
      "section3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fallback phone number & Coupling"])},
      "section4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Specifications"])},
      "secondaryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location ID"])},
      "syncId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync ID"])},
      "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitude"])},
      "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitude"])},
      "registrationRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration radius (in KM)"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "namePronunciation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronunciation"])},
      "streetNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House number"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
      "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZIP"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "phoneNumberAltIsFallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fallback phone number"])},
      "couplingEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate coupling"])},
      "phoneNumberAlt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative phone number 2"])},
      "phoneNumberAlt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative phone number"])},
      "customAdditionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])},
      "specificOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "group1AndName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netcenter/Region"])},
      "group2IdAndName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type/Level"])},
      "controlCenterIdAndName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control center"])},
      "safetyInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety instructions"])},
      "pdfTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF Template"])},
      "readerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reader"])},
      "readerTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a phone number with country code (e.g. +491231231234). The contents of the 'Pronunciation' field will be read to you via call."])},
      "readerPhonenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "activitySet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity set"])},
      "hasDisdi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital station diary"])}
    },
    "activitySet": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "addActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add activity"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "group2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type/Level"])},
      "group1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netcenter/Region"])},
      "couple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupling"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Activity set"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit activity set"])},
      "locationsHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location allocation"])},
      "toggleActivityFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show locations with different activity aswell"])}
    },
    "activity": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity description"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit activity"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New activity"])}
    },
    "register": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "guestAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest count"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign on"])},
      "unregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign off"])},
      "adressOfLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adress of registered Locations"])},
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed on"])},
      "unregistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed off"])}
    },
    "registerLocation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Report Filter"])}
    },
    "registerFilter": {
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "identificationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification number"])},
      "kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company internal identification"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "guestCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest count"])}
    },
    "confirm": {
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
    },
    "csv": {
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV file"])}
    },
    "userFilter": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User filter"])},
      "groudKid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company internal identification exists"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "groupActivitystatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity status"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activ"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactiv"])},
      "identificationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification number"])}
    },
    "reader": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reader"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a phone number with country code (e.g. +491231231234). The contents of the 'Pronunciation' field will be read to you via call."])},
      "callNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start call"])}
    },
    "userEditor": {
      "title": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New User"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit User"])}
      },
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "namePronunciation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronunciation"])},
      "identificationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification number"])},
      "kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company internal identification"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
      "hasProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write protected for Import?"])}
    },
    "locationFilter": {
      "secondaryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "controlCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control center"])},
      "coupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupling"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Filter"])},
      "group1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netcenter/Region"])},
      "group2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type/Level"])},
      "safetyInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety instructions"])},
      "customAdditionalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical place"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])}
    },
    "locationEditor": {
      "title": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Location"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Location"])}
      }
    },
    "locationCsvImport": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location CSV Import"])}
    },
    "locationCoupling": {
      "grid": {
        "foreignSystemName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreign System"])},
        "locationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZIP"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])}
      },
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adress"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Locationcoupling"])},
      "showCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show coupling"])},
      "hideCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimize coupling"])},
      "createNewCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Locationcoupling"])},
      "activeCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active couplings"])},
      "newCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Coupling"])},
      "sourceSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreign system"])}
    },
    "controlCenterEditor": {
      "title": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Control center"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Control center"])}
      }
    },
    "controlCenterFilter": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control center Filter"])}
    },
    "regionEditor": {
      "title": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Netcenter/Region"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Netcenter/Region"])}
      }
    },
    "locationReportsFilter": {
      "group1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netcenter/Region"])},
      "group2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type/Level"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "controlCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control center"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "lastRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last sign on/off"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adress"])}
    },
    "group1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "syncId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SyncId"])}
    },
    "group2": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])}
    },
    "categoryEditor": {
      "title": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New category"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit category"])}
      },
      "titleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "unicode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])}
    },
    "disdiFilter": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISDI filter"])},
      "diaryDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
      "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment"])},
      "createDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creationdate"])},
      "registrationStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sing on"])},
      "registrationEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign off"])},
      "userFullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "userPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phonenumber"])},
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])}
    },
    "disdiDetails": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISDI-Details"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "atbTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISDI-Details"])},
      "createDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])},
      "tabs": {
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "locationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locationdetails"])},
        "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])}
      },
      "location": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location details"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location name"])},
        "group2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type/Level"])},
        "group1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netcenter/Region"])},
        "CCName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control center"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phonenumber"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])}
      },
      "user": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff member details"])},
        "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phonenumber"])},
        "identificationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificationnumber"])},
        "kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KID"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])}
      },
      "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign on/off details"])},
      "registrationStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign on date"])},
      "registrationEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign off date"])}
    },
    "locationDisdiEditor": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location DISDI approval"])}
    },
    "general": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset to default"])},
      "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
    }
  }
}