export class LocationRegistrationStatus {
    coupledLocationRegistrationsCount:number; //gekoppelt
    registeredCount:number;
    unregisteredCount:number;
    registeredGuestCount:number;

    constructor(obj:any = null){
        this.coupledLocationRegistrationsCount = obj === null ? 0 : obj.coupledLocationRegistrationsCount;
        this.registeredCount = obj === null ? 0 : obj.registeredCount;
        this.unregisteredCount = obj === null ? 0 : obj.unregisteredCount;
        this.registeredGuestCount = obj === null ? 0 : obj.registeredGuestCount;
    }

    public isBusy():boolean {
        return this.registeredCount > 0 || this.coupledLocationRegistrationsCount > 0;
    }

    public totalRegistrationsCount():number {
        return this.coupledLocationRegistrationsCount + this.registeredCount + this.unregisteredCount;
    }

    public totalPersonCount():number {
        return this.coupledLocationRegistrationsCount + this.registeredCount + this.registeredGuestCount;
    }
}