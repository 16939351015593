import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {RegionsResponse} from "@/models/api/Responses";
import {HttpType} from "@/utilities/enums/Enums";
import {RegionInfo} from "@/models/group1/RegionInfo";
import {RegionRequest} from "@/models/api/Requests";

export class Group1Service {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }

    public async getRegions(viewtype: string = "selectable"):Promise<RegionsResponse> {
        const uri = this.uriHelper.regions.getRegionsPath() + `/viewtype/${viewtype}`;

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async addOrUpdateRegion(region:RegionInfo):Promise<any>{
        const uri = region.id < 0
            ? this.uriHelper.regions.getRegionsPath()
            : this.uriHelper.regions.getRegionPath(region.id);
        const requestBody = new RegionRequest(region);
        const reqType = region.id < 0
            ? HttpType.post
            : HttpType.put;

        return await this.webCaller.HttpSend(reqType, uri, requestBody);
    }

    public async deleteRegion(id:number):Promise<any>{
        const uri = this.uriHelper.regions.getRegionPath(id);

        return await this.webCaller.HttpSend(HttpType.delete, uri);
    }
}
