<template>
	<div>
		<Multiselect
				v-model="optionsSelected"
				:mode="mode"
				label="name"
				valueProp="id"
				:close-on-select="true"
				:create-option="false"
				:options="options"
				:taggable="true"
				:multiple="true"
				@change="changeOptions($event)"
    >
      <template v-slot:singlelabel="" v-if="props.icon">
        <div class="multiselect-single-label">
          <span :class="`icon-${optionsSelected}`"/>
        </div>
      </template>
      <template v-slot:option="{ option }" v-if="props.icon">
        <div class="singleLabel">
          <div class="text-2xl"><span :class="`icon-${option.name}`"/></div>
        </div>
      </template>
    </Multiselect>
	</div>
</template>

<script setup>
import Multiselect from '@vueform/multiselect'
import {toRef} from "vue";
import {defineProps, defineEmits} from "vue";

const props = defineProps({
	options: Array,
	mode: String,
	selected: Array,
  icon:Boolean
})

const emit = defineEmits(['selectChanged'])
let optionsSelected = toRef(props, 'selected');
const options = toRef(props, 'options');

function changeOptions(value) {
	emit('selectChanged', value);
}


</script>

<style scoped>

</style>
