<template>
	<div class="lds-ring"><div></div><div></div><div></div><div></div></div>
</template>

<script>
export default {
	name: "LoadingSpinner"
}
</script>

<style scoped>

</style>
