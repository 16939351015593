import {OidcMetadata, User, UserManager, UserManagerSettings} from 'oidc-client';

export default class OAuthService {
    public userManager: UserManager;

    constructor(authority:string, clientId:string, scopes:string, callback:string, silentCallback:string, metdata:OidcMetadata|undefined = undefined) {
        const settings: UserManagerSettings = {
            authority: authority,
            client_id: clientId,
            redirect_uri: window.location.origin + callback,
            silent_redirect_uri: window.location.origin + silentCallback,
            automaticSilentRenew: true,
            response_type: 'code',
            scope: scopes,
            post_logout_redirect_uri: window.location.origin + '/logout',
            filterProtocolClaims: false,
            metadata: metdata
        };

        this.userManager = new UserManager(settings);
    }

    public async login(): Promise<void> {
        return await this.userManager.signinRedirect();
    }

    public async logout(): Promise<void> {
        return await this.userManager.signoutRedirect();
    }

    public async refreshTokensSilently(): Promise<User | undefined> {
        return await this.userManager.signinSilentCallback();
    }
}
