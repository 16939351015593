import PolicyHelper from "@/utilities/helpers/PolicyHelper";
import {PermissionService} from "@/utilities/services/PermissionService";
import router from "@/router";
/*
    check if user needs to reauthenticate and reroute path to login-page
*/
export default function checkPermissionForRoute ({next, to}:any){
    const permissionService = new PermissionService();

    const policy = PolicyHelper.getPolicyFromRoute(to.path);
    if (!permissionService.userHasPermission(policy)){
        router.push("/error");
    }

    return next();
}
