import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {GeneralResponse, TokenResponse} from "@/models/api/Responses";
import {
    ChangePasswordRequest,
    LoginRequest,
    ResetPasswordEmailRequest,
    ResetPasswordRequest
} from "@/models/api/Requests";
import {ErrorLocationType, HttpType} from "@/utilities/enums/Enums";
import ErrorHandler from "@/utilities/helpers/ErrorHandler";

export class AccountService {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }

    public async login(email: string, password: string, rememberMe: boolean): Promise<TokenResponse> {
        const uri = this.uriHelper.account.getLoginPath();
        const requestBody = new LoginRequest(email, password, rememberMe);

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    public async sendResetPasswordMail(email: string, url: string): Promise<GeneralResponse> {
        const uri = this.uriHelper.account.getResetPasswordMailPath();
        const requestBody = new ResetPasswordEmailRequest(email, url);

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    public async resetPassword(token: string, email: string, password: string, passwordRepetition: string): Promise<GeneralResponse> {
        const uri = this.uriHelper.account.getResetPasswordPath();
        const requestBody = new ResetPasswordRequest(token, email, password, passwordRepetition);

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    public async changePassword(userId: string, oldPassword: string, newPassword: string, newPasswordRepetition: string): Promise<GeneralResponse> {
        const uri = this.uriHelper.account.getChangePasswordPath();
        const requestBody = new ChangePasswordRequest(userId, oldPassword, newPassword, newPasswordRepetition);

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    public async exchangeToken(errorLocationType:ErrorLocationType):Promise<string|null>{
        const uri = this.uriHelper.account.getExchangeTokenPath();

        const resp = await this.webCaller.HttpSend(HttpType.get, uri);
        console.log(resp)
        if(resp === null || resp === undefined || resp?.statusCode !== 0){
            ErrorHandler.handleError(resp, errorLocationType);
            return null;
        }

        return resp.token;
    }

    public async logout(): Promise<GeneralResponse> {
        const uri = this.uriHelper.account.getLogoutPath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }
}