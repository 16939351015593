<template>
  <div>

    <div class="block w-full">

      <div class="grid grid-cols-2 gap-4 w-full mb-5">
        <div>
          <div class="inputTitle">
            {{ $t('popups.settings.coupledSystems.name') }} *
          </div>
          <div class="inputField flex-grow" >
            <div :class="{ error: v$.name.$errors.length }">
              <input type="text" v-model="popupData.name" class="w-full" /><br/>
              <div class="input-errors" v-for="error of v$.name.$errors" :key="error.$uid">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="inputTitle">
            {{ $t('popups.settings.coupledSystems.baseUrl') }} *
          </div>
          <div class="inputField flex-grow" >
            <div :class="{ error: v$.baseUrl.$errors.length }">
              <input type="text" v-model="popupData.baseUrl" class="w-full" /><br/>
              <div class="input-errors" v-for="error of v$.baseUrl.$errors" :key="error.$uid">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-2 mt-2 text-grey">
        <h4>{{ $t('popups.settings.coupledSystems.partnerSection') }}</h4>
      </div>
      <div class="grid grid-cols-1 gap-4 w-full mb-5">
        <div>
          <div class="inputTitle">
            {{ $t('popups.settings.coupledSystems.nameOfPartner') }}
          </div>
          <div class="inputField flex-grow" >
            <input type="text" v-model="popupData.contactName" class="w-full" /><br/>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 w-full mb-5">
        <div>
          <div class="inputTitle">
            {{ $t('popups.settings.coupledSystems.email') }} *
          </div>
          <div class="inputField flex-grow" >
            <div :class="{ error: v$.phoneNumber.$errors.length }">
              <input type="text" v-model="popupData.contactEmail" class="w-full" /><br/>
              <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="inputTitle">
            {{ $t('popups.settings.coupledSystems.phoneNumber') }} *
          </div>
          <div class="inputField flex-grow" >
            <div :class="{ error: v$.phoneNumber.$errors.length }">
              <input type="text" v-model="popupData.contactPhoneNumber" class="w-full" /><br/>
              <div class="input-errors" v-for="error of v$.phoneNumber.$errors" :key="error.$uid">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-2 mt-2 text-grey">
      <h4>{{ $t('popups.settings.coupledSystems.loginData') }}</h4>
    </div>
    <div class="grid grid-cols-2 gap-4 w-full mb-5">
      <div>
        <div class="inputTitle">
          {{ $t('popups.settings.coupledSystems.loginName') }} *
        </div>
        <div class="inputField flex-grow" >
          <div :class="{ error: v$.loginName.$errors.length }">
            <input type="text" v-model="popupData.loginName" class="w-full" /><br/>
            <div class="input-errors" v-for="error of v$.loginName.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="inputTitle">
          {{ $t('popups.settings.coupledSystems.password') }} *
        </div>
        <div class="inputField flex-grow" >
          <div :class="{ error: v$.password.$errors.length }">
            <input type="password" v-model="popupData.password" class="w-full" /><br/>
            <div class="input-errors" v-for="error of v$.password.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import {computed, defineProps, ref, toRef} from "vue";
import {useI18n} from "vue-i18n";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";


const { t } = useI18n();

const props = defineProps({
  data: Object
})
const popupData = toRef(props, 'data');

const rules = computed(() => {
  const rules = {
    name: { required: helpers.withMessage(t('validators.required'), required) },
    baseUrl: { required: helpers.withMessage(t('validators.required'), required) },
    phoneNumber: { required: helpers.withMessage(t('validators.required'), required) },
    email: { required: helpers.withMessage(t('validators.required'), required) },
    loginName: { required: helpers.withMessage(t('validators.required'), required) },
    password: { required: helpers.withMessage(t('validators.required'), required) },
  }
  return rules
})

const v$ = ref(useVuelidate(rules, popupData))
</script>

<style scoped>

</style>
