<template>
	<div>

		<div class="block w-full">
			<div class="grid grid-cols-2 gap-4 w-full">
				<div>
					<div class="inputTitle">
						{{ $t('popups.csv.file') }}
					</div>
					<div class="inputField flex-grow" >
						<input type="file" @change="fileChanged">
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script setup>
import { useGlobalStore } from "@/stores/GlobalStore";

const globalStore = useGlobalStore();

const fileChanged = (event) =>{
	globalStore.uploadedFile = event.target.files[0];
};
</script>
<style scoped>

</style>
