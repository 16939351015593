import { ContactInfo } from "../ContactInfo";
import { SafetyInstructionInfo } from "../safetyInstruction/SafetyInstructionInfo";
import { LocationRegistrationStatus } from "../registration/LocationRegistrationStatus";

export class LocationInfo {
    id: number = -1;
    secondaryId: string = "";
    syncId?: string = "";
    longitude?: number = undefined;
    latitude?: number = undefined;
    registrationRadius?: number = undefined;
    name: string = "";
    namePronunciation: string = "";
    contactInfo: ContactInfo = new ContactInfo();
    email: string = "";
    phoneNumber: string = "";
    phoneNumberAlt1?: string = undefined;
    phoneNumberAlt2?: string = undefined;
    phoneNumberAltIsFallback: boolean = false;
    couplingEnabled: boolean = false;
    coupledSystemsName: Array<string> = [];
    customAdditionalInformation?: string = undefined;
    registrationStatus: LocationRegistrationStatus = new LocationRegistrationStatus;
    group1Id?: number = undefined;
    group1SyncId: string = "";
    group2Id?: number = undefined;
    controlCenterId?: number = undefined;
    controlCenterSyncId: string = "";
    group1Name: string = "";
    group2Name: string = "";
    controlCenterName: string = "";
    safetyInstructions: Array<SafetyInstructionInfo> = [];
    pdfTemplate?: number = undefined;
    systemCouplingIdentifier?: string = undefined;
    activitySetId:number | null = null;
    hasDisdi:boolean = false;
    disdiCount:number = -1;
}