<template>
    <div id="errorpage" class="w-full h-full">
			<div class="flex flex-col h-full justify-center items-center">
				<h1>{{ $t("403.headline") }}</h1>
				<h3>{{ $t("403.subline") }}</h3>
				<div class="description text-center">
					<span v-html="$t('403.description')"></span>
				</div>
			</div>
    </div>
</template>

<script setup lang="ts">

</script>


<style scoped>

</style>
