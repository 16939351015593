<template>
  <input class="w-full"
         :type=inputType()
         :value="$attrs.modelValue"
         :disabled="props.disabled"
         @input="$emit('update:modelValue', $event.target.value)"
         :placeholder="props.placeholder"
  />
</template>

<script setup>

import {defineProps} from "vue";

const props = defineProps({
  type: String,
  disabled: String,
  placeholder: String
})

const inputType = () => {
  return props.type || 'text'; // default is text
}

/*
import {ref} from "vue";


const input = ref();
import {toRef, defineProps} from "vue";



const input = toRef(props, 'data');
*/



</script>

<style scoped>

</style>
