<template>
	<div id="modal" v-if="showModal" @keydown.esc="closeModal" tabindex="-1" ref="modal">
		<div :class="globalStore.modal.bigMode ? 'big':''" class="modalWrapper">
			<div class="title">{{ modalTitle }}</div>
			<div class="popupContent" >
				<!--  Variable content -->
				<div>
					<div class="w-full popupInputs" id="popupWrapper">

						<div v-if="loading" class="text-center">
							<LoadingSpinner/>
						</div>
						<div v-else>
							<div v-if="modalType === PopupType.locationEditor">
								<LocationInfoEditor :data="dataCopy"  />
							</div>
							<div v-else-if="modalType === PopupType.locationFilterPopup">
								<LocationFilterPopup :data="dataCopy"/>
							</div>
							<div v-else-if="modalType === PopupType.locationCouplingPopup">
								<LocationCouplingPopup :data="dataCopy"/>
							</div>
							<div v-else-if="modalType === PopupType.userFilterPopup">
								<UserFilterPopup :data="dataCopy"/>
							</div>
							<div v-else-if="modalType === PopupType.userPopup">
								<UserPopup :data="dataCopy"/>
							</div>
							<div v-else-if="modalType === PopupType.csvImportPopup">
								<CSVImport/>
							</div>
							<div v-else-if="modalType === PopupType.group2Popup">
								<Group2Popup :data="dataCopy"/>
							</div>
							<div v-else-if="modalType === PopupType.group2Locations">
								<LocationsPopup :data="dataCopy"/>
							</div>
							<div v-else-if="modalType === PopupType.controlCenterEditor">
								<ControlCenterEditor :data="dataCopy"/>
							</div>
							<div v-else-if="modalType === PopupType.regionEditor">
								<RegionEditor :data="dataCopy" />
							</div>
							<div v-else-if="modalType === PopupType.safetyInstructions">
								<SafetyInstructionsPopup :data="dataCopy"/>
							</div>
							<div v-else-if="modalType === PopupType.safetyInstructionLocationsPopup">
								<SafetyInstructionLocationsPopup :data="dataCopy" />
							</div>
							<div v-else-if="modalType === PopupType.locationReportsPopup">
								<InstantMessagePopup :data="dataCopy"/>
							</div>
							<div v-else-if="modalType === PopupType.locationReportsDetailsPopup">
								<LocationReportsDetailsPopup :data="dataCopy"/>
							</div>
              <div v-else-if="modalType === PopupType.registerPopup">
                <RegisterPopup :data="dataCopy"/>
              </div>
							<div v-else-if="modalType === PopupType.registrationUserHistory">
								<registration-user-history :data="dataCopy" />
							</div>
							<div v-else-if="modalType === PopupType.registrationFilterPopup">
								<RegistrationFilterPopup :data="dataCopy"/>
							</div>
              <div v-else-if="modalType === PopupType.locationReportsFilterPopup">
                <LocationReportsFilterPopup :data="dataCopy"/>
              </div>
							<div v-else-if="modalType === PopupType.activitySetLocationsPopup">
								<ActivitySetLocationsPopup :data="dataCopy"/>
							</div>
							<div v-else-if="modalType === PopupType.activitySetEditPopup">
								<ActivitySetEditPopup :data="dataCopy"/>
							</div>
							<div v-else-if="modalType === PopupType.activityEditPopup">
								<ActivityEditPopup :data="dataCopy" />
							</div>
							<div v-else-if="modalType === PopupType.providerManagementEditPopup">
								<ProviderEditPopup :data="dataCopy" />
							</div>
							<div v-else-if="modalType === PopupType.providerNewCompanyWorkerPopup">
								<NewStaffMember :data="dataCopy" />
							</div>
							<div v-else-if="modalType === PopupType.providerCompanyWorkerEditPopup">
								<ProviderStaffMemberDetails :data="dataCopy"/>
							</div>
              <div v-else-if="modalType === PopupType.locationReportsUserHistoryPopup">
                <LocationReportsUserHistoryPopup :data="dataCopy"/>
              </div>
              <div v-else-if="modalType === PopupType.selectSimilarCompany">
                <SimilarCompaniesSelectionPopup />
              </div>
              <div v-else-if="modalType === PopupType.settingsEditSpokenText">
                <EditSpokenText :data="dataCopy" />
              </div>
              <div v-else-if="modalType === PopupType.settingsEditSystemSetting">
                <EditSystemSetting :data="dataCopy"/>
              </div>
              <div v-else-if="modalType === PopupType.settingsEditCoupledSystems">
                <EditCoupledSystem :data="dataCopy" />
              </div>
              <div v-else-if="modalType === PopupType.settingsResetCredentials">
                <ResetCredentials />
              </div>
              <div v-else-if="modalType === PopupType.changeActivityForRegistration">
                <RegistrationChangeActivityText :data="dataCopy"/>
              </div>
              <div v-else-if="modalType === PopupType.categoryEditor">
                <CategoryEditor :data="dataCopy"/>
              </div>
              <div v-else-if="modalType === PopupType.disdiFilterPopup">
                <DisdiFilterPopup :data="dataCopy"/>
              </div>
              <div v-else-if="modalType === PopupType.disdiDetailsPopup">
                <DisdiDetailsPopup :data="dataCopy"/>
              </div>
              <div v-else-if="modalType === PopupType.locationDisdiEditor">
                <LocationDisdiEditor :data="dataCopy"/>
              </div>
							<div v-else>
								{{ PopupType[modalType] }}
							</div>
						</div>
					</div>
				</div>

			</div>
			<div class="footer flex">
				<div class="flex-1">
					<div class="floatingError mr-4">
						<Error :errorLoc="ErrorLocationType.popup"/>
					</div>
					<button class="btn mr-2" v-if="resetButtonState" @click="resetPreferences()">{{$t('popups.general.reset')}}<span class="icon-reset ml-1"></span></button>
				</div>

				<div class="text-right">
          <button class="btn mr-2" @click="closeModal()" v-if="props.showCancelButton">{{$t('popups.general.cancel')}}</button>
					<button class="primary" @click="confirmModal()" v-if="isFilterPopup()" :disabled="loadingConfirm">
            <TextOrSpinner :text="`${lengthAfterFilter} ${t('popups.general.results')}`" :spin="loadingConfirm"/>
          </button>
					<button class="primary" id="okButton" @click="confirmModal()" v-else-if="popupNeedsCallbackButton()" :disabled="loadingConfirm">
            <TextOrSpinner :text="t('popups.general.confirm')" :spin="loadingConfirm"/>
          </button>
				</div>

			</div>
		</div>

	</div>
</template>

<script setup>
import {computed, defineProps, ref, watchEffect} from 'vue';
import { useGlobalStore } from "@/stores/GlobalStore";
import {PopupType, TableType} from "@/utilities/enums/Enums";
import LocationInfoEditor from "@/components/popups/locations/LocationInfoEditor";
import LocationFilterPopup from "@/components/popups/locations/LocationFilterPopup";
import LocationCouplingPopup from "@/components/popups/locations/LocationCouplingPopup";
import UserFilterPopup from "@/components/popups/users/UserFilterPopup";
import LoadingSpinner from "@/components/LoadingSpinner";
import Error from "@/components/Error";
import { ErrorLocationType } from "@/utilities/enums/Enums";
import UserPopup from "@/components/popups/users/UserPopup";
import CSVImport from "@/components/popups/CSVImport";
import Group2Popup from "@/components/popups/group2/Group2Popup";
import LocationsPopup from "@/components/popups/locations/LocationsPopup";
import ControlCenterEditor from "@/components/popups/controlCenter/ControlCenterEditor";
import RegionEditor from "@/components/popups/group1/Group1Popup";
import SafetyInstructionsPopup from "@/components/popups/safetyInstructions/SafetyInstructionsPopup";
import SafetyInstructionLocationsPopup from "@/components/popups/safetyInstructions/SafetyInstructionLocationsPopup";
import InstantMessagePopup from "@/components/popups/InstantMessagePopup";
import {useI18n} from "vue-i18n";
import RegisterPopup from "@/components/popups/registation/RegisterPopup";
import LocationReportsDetailsPopup from "@/components/popups/locationReports/LocationReportsDetailsPopup";
import LocationReportsFilterPopup from "@/components/popups/locationReports/LocationReportsFilterPopup";
import LocationReportsUserHistoryPopup from "@/components/popups/locationReports/LocationReportsUserHistoryPopup";
import RegistrationUserHistory from "@/components/popups/registation/RegistrationUserHistory";
import RegistrationFilterPopup from "@/components/popups/registation/RegistrationFilterPopup";
import ActivitySetLocationsPopup from "@/components/popups/activitySets/ActivitySetLocationsPopup";
import ActivitySetEditPopup from "@/components/popups/activitySets/ActivitySetEditPopup";
import ActivityEditPopup from "@/components/popups/activitySets/ActivityEditPopup";
import SimilarCompaniesSelectionPopup from "@/components/popups/providerManagement/SimilarCompaniesSelectionPopup";
import ProviderEditPopup from "@/components/popups/providerManagement/ProviderEditPopup";
import NewStaffMember from "@/components/popups/providerManagement/ProviderNewCompanyWorker";
import ProviderStaffMemberDetails from "@/components/popups/providerManagement/ProviderCompanyWorkerEdit";
import EditSpokenText from "@/components/popups/settings/EditSpokenText";
import EditSystemSetting from "@/components/popups/settings/EditSystemSetting";
import EditCoupledSystem from "@/components/popups/settings/EditCoupledSystem";
import ResetCredentials from "@/components/popups/settings/ResetCredentials";
import RegistrationChangeActivityText from "@/components/popups/registation/RegistrationChangeActivityText";
import TextOrSpinner from "@/components/TextOrSpinner";
import CategoryEditor from "@/components/popups/category/CategoryEditor.vue";
import DisdiFilterPopup from "@/components/popups/disdi/DisdiFilterPopup.vue";
import DisdiDetailsPopup from "@/components/popups/disdi/DisdiDetailsPopup.vue";
import LocationDisdiEditor from "@/components/popups/locations/LocationDisdiEditor";


const { t } = useI18n();

const props = defineProps({
	showCancelButton: {
		type: Boolean,
		default: true
	},
	showResetButton: {
		type: Boolean,
		default: true
	},
	data: Object
});

const loading = ref(false);
const loadingConfirm = ref(false);
const globalStore = useGlobalStore();



// Make a REF Copy of the Data
const dataCopy = ref(null);

// make flat copy of props.data by hand, otherwise v-model pushes changes from the popup through to the record data in the grid, which is buggy and weird (grid data changes but db-data doesnt)
watchEffect(()=>{
  const copy = {};
  for (const keyValueArray of Object.entries(props.data)){
    copy[keyValueArray[0]] = keyValueArray[1];
  }
  dataCopy.value = copy;
})



const isFilterPopup = ()=>{
  return modalType.value === PopupType.registrationFilterPopup
      || modalType.value === PopupType.userFilterPopup
      || modalType.value === PopupType.locationFilterPopup
      || modalType.value === PopupType.locationReportsFilterPopup
      || modalType.value === PopupType.disdiFilterPopup; //TODO default filter? -> AKC
};

const popupNeedsCallbackButton = ()=>{
  return modalType.value !== PopupType.registerPopup
      && modalType.value !== PopupType.locationCouplingPopup
      && modalType.value !== PopupType.registrationUserHistory
      && modalType.value !== PopupType.locationReportsUserHistoryPopup
      && modalType.value !== PopupType.locationReportsDetailsPopup
      && modalType.value !== PopupType.settingsResetCredentials
      && modalType.value !== PopupType.disdiDetailsPopup;
}

const showModal = computed(() => {
	return globalStore.modal.show;
});

const modalTitle = computed(() => {
	return globalStore.modal.title;
});

const modalType = computed(() => {
	return globalStore.modal.popupType;
});

const resetButtonState = computed(() => {
	return globalStore.modal.resetButton;
});

const lengthAfterFilter = computed(()=>{
	return globalStore.countAfterFilters;
});


const closeModal = async () => {
	globalStore.closeModal();
};

const confirmModal = async () => {
  loadingConfirm.value = true;

  // update contentObject before proceeding with save action (not done automatically since it is flatCopy)
  globalStore.modal.contentObject = dataCopy.value;

	const callback = globalStore.modal.action;
	const isValid = await globalStore.modal.validator.$validate();

  try {
    // check if callback action is a function and run validation
    if (typeof (callback) === "function" && isValid) await callback(); // closing should be done via the callback
    else {
      globalStore.error.type = "error";
      globalStore.error.text = t("global.validationFailed");
      globalStore.error.code = 0;
      globalStore.error.location = ErrorLocationType.popup;

      const targetElement = document.querySelector(".error");
      targetElement.scrollIntoView({behavior: "smooth", block: 'center'});
    }
  }
  catch {
    loadingConfirm.value = false;
  }

  loadingConfirm.value = false;
};

const resetPreferences = () => {
	const filteredDefaultHiddenCols = globalStore.settings.defaultHiddenColumns.filter((dhc)=>{
		switch(modalType.value){
			case PopupType.userFilterPopup:
				return dhc.tableName === TableType[TableType.user];
			case PopupType.locationFilterPopup:
				return dhc.tableName === TableType[TableType.location];
			case PopupType.locationReportsFilterPopup:
				return dhc.tableName === TableType[TableType.registerLocation];
      case PopupType.registrationFilterPopup:
        return dhc.tableName === TableType[TableType.register];
      case PopupType.disdiFilterPopup:
        return dhc.tableName === TableType[TableType.disdi];
			default:
				console.log("PopupType not know when resetting filters");
				return false;
		}
	});
	globalStore.setTmpFilters([]);
	globalStore.setTmpHiddenColumns(filteredDefaultHiddenCols);
	globalStore.setCountAfterFilters(globalStore.gridStore.storage.allValues.length);
};

</script>

<style lang="scss">
	#modal {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		z-index: 4999;
		background: rgba(0,0,0,0.5);
		flex-flow: column;
		.modalWrapper {
			@apply rounded-xl;
			background: $white;
			width: 800px;
		}
		.big {
			width: 1000px;
		}
		.title {
			@apply w-full rounded-t-xl  pb-2 mb-2 text-center py-3;
			color: $nightGrey;
			background: $white;
			border-bottom: 1px solid $foggyGrey;
		}
		.inputTitle {
			@apply mb-1;
			font-size: 12px;
			color: $nightGrey;
			font-family: 'Open Sans Regular', sans-serif;
		}
		.inputRow {
			@apply my-3 p-1;
			display: flex;
			flex-direction: column;
			flex-basis: 100%;
			flex: 1;
		}
		.groupTitle {
		}
		.groupInputs {
			flex-direction: row;
			flex-wrap: wrap;
			column-gap: 20px;
			width: 100%;
		}
		.inputGroup {
			width: 100%;
			@apply pb-4 mt-4;
			border-bottom: 1px solid $foggyGrey;
		}
		.popupContent {
			padding: 20px;
			.popupInputs {
				@apply pr-4;
				max-height: 60vh;
				overflow-y: auto;
			}
		}
		.footer {
			@apply pt-2 mt-2 rounded-b-xl;
			padding: 20px;
			background: $white;
			border-top: 1px solid $foggyGrey;
		}
		.floatingError {
			//position: absolute;
			//width: calc(100% - 65px);
			//bottom: 0px;
		}
	}
</style>
