<template>
	<div>

		<div class="block w-full">
			<div class="grid grid-cols-2 gap-4 w-full">
				<div>
					<div class="inputTitle">
						{{ $t('popups.userEditor.firstName') }} *
					</div>
					<div class="inputField flex-grow" >
						<div :class="{ error: v$.firstName.$errors.length }">
							<input type="text" v-model="popupData.firstName" class="w-full" /><br/>
							<div class="input-errors" v-for="error of v$.firstName.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.userEditor.lastName') }} *
					</div>
					<div class="inputField flex-grow" >
						<div :class="{ error: v$.lastName.$errors.length }">
							<input type="text" v-model="popupData.lastName" class="w-full" /><br/>
							<div class="input-errors" v-for="error of v$.lastName.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.userEditor.namePronunciation') }}
					</div>
					<div class="inputField flex-grow" >
              <Text v-model="popupData.namePronunciation" />
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.userEditor.identificationNumber') }} *
					</div>
					<div class="inputField flex-grow" >
						<Text v-model="popupData.identificationNumber" />
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.userEditor.kid') }}
					</div>
					<div class="inputField flex-grow" >
						<Text v-model="popupData.kid" />
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.userEditor.email') }} *
					</div>
					<div class="inputField flex-grow" >
						<div :class="{ error: v$.email.$errors.length }">
							<input type="text" v-model="popupData.email" class="w-full" /><br/>
							<div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.userEditor.phoneNumber') }} *
					</div>
					<div class="inputField flex-grow" >
						<div :class="{ error: v$.phoneNumber.$errors.length }">
							<input type="text" v-model="popupData.phoneNumber" class="w-full" :placeholder="phoneNumberPlaceholder"/><br/>
							<div class="input-errors" v-for="error of v$.phoneNumber.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.userEditor.info') }}
					</div>
					<div class="inputField flex-grow" >
						<Text v-model="popupData.info" />
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.userEditor.roles') }}
					</div>
					<div class="inputField flex-grow" >
            <Tags :selected="popupData.roles" :options="rolesList" @selectChanged="(value)=>{popupData.roles = value}"/>
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.userEditor.hasProtection') }}
					</div>
					<div class="inputField flex-grow" >
						<input type="checkbox" v-model="popupData.hasProtection" />
					</div>
				</div>
			</div>
		</div>

		<hr class="my-4" />
		<div class="block w-full">
			<h3 class="mb-4">{{ $t('popups.reader.title') }}
				<span class="tooltip infoIcon">
					<span class="icon-info"></span>
					<span class="infoText tooltiptext shadow">
						{{ $t('popups.reader.description') }}
					</span>
				</span>
			</h3>
			<div class="grid grid-cols-2 gap-4 w-full">
				<div>
					<div class="inputTitle">
						{{ $t('popups.reader.callNumber') }}
					</div>
					<div class="inputField flex-grow" >
            <div :class="{ error: testCallValidator.numberToCall.$errors.length }">
              <Text v-model="callNumber" @focusout="hideCallValidatorErrors" :placeholder="phoneNumberPlaceholder"/>
              <div class="input-errors" v-for="error of testCallValidator.numberToCall.$errors" :key="error.$uid">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <button class="primary mt-2" @click="voiceTestCall" :disabled="loading">
              <TextOrSpinner :text="$t('popups.reader.button')" :spin="loading"/>
            </button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script setup>
import {defineProps, toRef, ref, computed, onMounted} from "vue";
import Text from "@/components/inputs/Text";
import {email, helpers, required, requiredIf} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import {$t} from "@/utilities/i18n/config";
import {useGlobalStore} from "@/stores/GlobalStore";
import {ErrorLocationType} from "@/utilities/enums/Enums";
import Tags from "@/components/inputs/Tags";
import {MiscService} from "@/utilities/services/entities/MiscService";
import {UserService} from "@/utilities/services/entities/UserService";
import TextOrSpinner from "@/components/TextOrSpinner";
import {phoneNumberPlaceholder} from "@/AppConfig";


const miscService = new MiscService();
const userService = new UserService();

const props = defineProps({
	data: Object
})

const popupData = toRef(props, 'data');
const globalStore = useGlobalStore();

const rolesList = ref([]);

const callNumber = ref("");
const loading = ref(false);

const voiceTestCall = async () => {
  loading.value = true;

  const isValid = await testCallValidator.value.$validate();

  if(!isValid) {
    loading.value = false;

    const targetElement = document.querySelector(".error");
    targetElement.scrollIntoView({behavior: "smooth", block: 'center'});

    return;
  }

  // check if there is a name pronunciation
  let nameToRead = "";
  if(popupData.value.namePronunciation === "") {
    nameToRead = popupData.value.firstName + " " + popupData.value.lastName;
  } else {
    nameToRead = popupData.value.namePronunciation;
  }

	// sadly can't get generalResponse here so it is fire and forget
	await miscService.postVoiceTestCall(nameToRead, callNumber.value);

  loading.value = false;
};


const phoneNumbersValidator = helpers.regex(/^\+[0-9]+$/);

const rules = computed(() => {

	const rules = {
		firstName: { required: helpers.withMessage($t('validators.required'), required), },
		lastName: { required: helpers.withMessage($t('validators.required'), required), },
		email: {
      email: helpers.withMessage($t('validators.email'), email),
			requiredIf: helpers.withMessage(
					$t('validators.requiredIf'),
					requiredIf(popupData.value.phoneNumber === '')
			),
		},
		phoneNumber: {
			phoneNumbersValidator: helpers.withMessage(
					$t('validators.phoneNumber'),
					phoneNumbersValidator
			),
			requiredIf: helpers.withMessage(
					$t('validators.requiredIf'),
					requiredIf(popupData.value.email === '')
			),
		},
	}
	return rules
})
const v$ = ref(useVuelidate(rules, popupData))

const readerRules = computed(()=>{
  return {
    // pronunciation: {
    //   required: helpers.withMessage(
    //       $t('validators.required'),
    //       required
    //   )
    // },
    numberToCall: {
      phoneNumbersValidator: helpers.withMessage(
          $t('validators.phoneNumber'),
          phoneNumbersValidator
      ),
      required: helpers.withMessage(
          $t('validators.required'),
          required
      )
    },
  }
})
const callData = computed(()=>{
  return {
    numberToCall: callNumber.value
  }
})
const testCallValidator = ref(useVuelidate(readerRules, callData));
const hideCallValidatorErrors = ()=>{
  testCallValidator.value.$reset();
}


onMounted(async () => {
	globalStore.modal.validator = v$;

  rolesList.value = await userService.getUserRoles(ErrorLocationType.popup);

  const convertedArray = [];
  for (const r of popupData.value.roles){
    const convertedRole = rolesList.value.find(cr => cr.name === r);
    convertedArray.push(convertedRole);

    const index = rolesList.value.indexOf(convertedRole);
    rolesList.value.splice(index, 1);
  }
  popupData.value.roles = convertedArray;
})

</script>

<style scoped>

</style>
