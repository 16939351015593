<template>
	<div>

		<div class="block w-full">
			<div class="grid grid-cols-1 gap-4 w-full mb-5">
				<div>
					<div class="inputTitle">
						{{ $t('popups.settings.systemSettings.name') }}
					</div>
					<div class="inputField flex-grow" >
							<input type="text" v-model="popupData.name" disabled class="w-full" /><br/>
					</div>
					<br/>
					<div class="inputTitle">
						{{ $t('popups.settings.systemSettings.value') }}
					</div>
					<div class="inputField flex-grow" >
						<div v-if="popupData.value.length > stringLengthForTextarea">
							<textarea v-model="popupData.value" class="w-full" rows="5"></textarea>
						</div>
						<div v-else>
							<input type="text" v-model="popupData.value" class="w-full" /><br/>
						</div>
					</div>
					<div v-html="popupData.helpText" class="helpText mt-4"></div>
				</div>
			</div>
		</div>

	</div>
</template>

<script setup>
import {defineProps, toRef} from "vue";

const stringLengthForTextarea = 100;
const props = defineProps({
	data: Object
})

const popupData = toRef(props, 'data');
</script>

<style scoped>

</style>
