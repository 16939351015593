import axios, {AxiosInstance} from "axios";
import sleeper from "@/utilities/helpers/Sleeper";

export class BlobUploadService{
    private axiosInstance : AxiosInstance;

    constructor(){
        this.axiosInstance = axios.create();
    }

    public async UploadFile(file:File, url:string, callback:Function|null = null):Promise<boolean>{
        try {
            const resp = await this.axiosInstance.request({
                method: "put",
                url: url,
                data: await file.arrayBuffer(),
                headers: {
                    'x-ms-blob-type': 'BlockBlob',
                    'Content-Type': file.type
                },
                onUploadProgress: (p) => {
                    if(callback === null) return
                    callback(`${~~((p.loaded / p.total)*100)}%`)
                },
            });

            return resp.status >= 200 && resp.status <= 299
        } catch (e){
            console.log(e)
            return false;
        }
    }
}