<template>
	<div class="tagsWrapper" @click="(e)=>preventShowingOptiosOnRemove(e)" tabindex="0" @focusout="showOptions = false">
		<draggable :list="selectedOptions" @start="drag=true" @end="drag=false" item-key="id" class="flex flex-wrap">
			<template #item="{element}">
				<span class="selectedOption flex"><span class="text">{{ element.name }}</span><span class="removeOption" @click="removeSelectedOption(element)"><span class="icon-schliessen"></span></span></span>
			</template>
		</draggable>
		<div v-if="showOptions" class="tagOptions">
			<ul>
				<li v-for="option in selectableOptions" :key="option.id" :value="option.id" @click="selectOption(option)">{{ option.name }}</li>
			</ul>
		</div>
		<div class="selectArrow">
			<span :class="showOptions ? 'icon-pfeil-hoch' : 'icon-pfeil-runter'"></span>
		</div>
	</div>
</template>

<script setup>
import {defineProps, ref, toRef, defineEmits} from "vue";
import draggable from 'vuedraggable'

const emit = defineEmits(['selectChanged'])
const props = defineProps({
	options: Array,
	selected: Array,
});

const selectableOptions = toRef(props, 'options');
const selectedOptions = toRef(props, 'selected');
const showOptions = ref(false);
let drag = false;

const selectOption = (option) => {
	selectedOptions.value.push(option);

	const index = selectableOptions.value.indexOf(option);
	selectableOptions.value.splice(index, 1);

  emit('selectChanged', selectedOptions.value);
}

const preventShowingOptiosOnRemove = ({target}) =>{
  if(target.classList.contains('removeOption')) return;
  showOptions.value = !showOptions.value;
}

const removeSelectedOption = (option) => {
  const index = selectedOptions.value.indexOf(option);
  selectedOptions.value.splice(index, 1);

  if(!selectableOptions.value.some(ele => ele.id === option.id)) selectableOptions.value.push(option);

  showOptions.value = false;

  emit('selectChanged', selectedOptions.value);
}

</script>

<style scoped>

</style>
