import {defineStore} from "pinia";

export const useDisdiMaintenanceStore = defineStore('DisdiMaintenanceStore', {
    state: () => {
        return {
            currentDisdiId:"",
        }
    },
    actions: {
    }
})
