<template>
	<div id="successWrapper">
		<div class="flex items-center justify-center h-screen">
			<span class="successBox">{{ globalStore.success.text }} <span class="successIcon"><span class="icon-check"></span></span></span>
		</div>
	</div>
</template>

<script setup>
import {onMounted} from "vue";
import { useGlobalStore } from "@/stores/GlobalStore";
import sleep from "@/utilities/helpers/Sleeper";

const globalStore = useGlobalStore();

onMounted(async () => {
	await sleep(1000);
	globalStore.success.show = false;
})
</script>

<style lang="scss">

</style>
