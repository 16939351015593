import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {ControlCentersResponse} from "@/models/api/Responses";
import {HttpType} from "@/utilities/enums/Enums";
import {ControlCenterInfo} from "@/models/controlCenter/ControlCenterInfo";
import {ControlCenterRequest} from "@/models/api/Requests";

export class ControlCenterService {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }

    public async getControlCenters(viewtype: string = "selectable"):Promise<ControlCentersResponse> {
        const uri = this.uriHelper.controlCenters.getControlCentersPath() + `/viewtype/${viewtype}`;

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async addOrUpdateControlCenter(center:ControlCenterInfo):Promise<any>{
        const uri = center.id < 0
            ? this.uriHelper.controlCenters.getControlCentersPath()
            : this.uriHelper.controlCenters.getControlCenterPath(center.id);
        const requestBody = new ControlCenterRequest(center);
        const reqType = center.id < 0
            ? HttpType.post
            : HttpType.put;

        return await this.webCaller.HttpSend(reqType, uri, requestBody);
    }

    public async deleteControlCenter(id:number):Promise<any>{
        const uri = this.uriHelper.controlCenters.getControlCenterPath(id);

        return await this.webCaller.HttpSend(HttpType.delete, uri);
    }
}
