import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {SafetyInstructionResponse} from "@/models/api/Responses";
import {HttpType} from "@/utilities/enums/Enums";
import {SafetyInstructionInfo} from "@/models/safetyInstruction/SafetyInstructionInfo";
import {SafetyInstructionRequest} from "@/models/api/Requests";

export class SafetyInstructionService {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }

    public async getSafetyInstructions(viewtype: string = "selectable"):Promise<SafetyInstructionResponse> {
        const uri = this.uriHelper.safetyInstructions.getSafetyInstructionsPath() + `/viewtype/${viewtype}`;

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async addOrUpdateSafetyInstruction(si:SafetyInstructionInfo):Promise<any>{
        const uri = si.id < 0
            ? this.uriHelper.safetyInstructions.getSafetyInstructionsPath()
            : this.uriHelper.safetyInstructions.getSafetyInstructionPath(si.id);
        const requestBody = new SafetyInstructionRequest(si);
        const reqType = si.id < 0
            ? HttpType.post
            : HttpType.put;

        return await this.webCaller.HttpSend(reqType, uri, requestBody);
    }

    public async deleteSafetyInstruction(id:number):Promise<any>{
        const uri = this.uriHelper.safetyInstructions.getSafetyInstructionPath(id);

        return await this.webCaller.HttpSend(HttpType.delete, uri);
    }

    public async AcceptCoupledSafetyInstruction(id: number) {
        const uri = this.uriHelper.safetyInstructions.getAcceptCoupledSafetyInstructionPath(id);

        return await this.webCaller.HttpSend(HttpType.post, uri);
    }
}
