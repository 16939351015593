import axios, {AxiosInstance, AxiosRequestConfig, Method} from "axios";
import {useGlobalStore} from "@/stores/GlobalStore";
import {HttpType, PdfExportActionEnum} from "@/utilities/enums/Enums";
import {GeneralResponse} from "@/models/api/Responses";
import {TokenService} from "@/utilities/services/TokenService";
import {baseURL} from "@/AppConfig";
import {PathHelper} from "@/utilities/helpers/PathHelper";


export type HttpClientResponse<T> = [null, T] | [Error, any]

export class WebCallService {
    private axiosInstance : AxiosInstance;
    private url: string;
    private readonly baseUrl: string;
    private tokenService:TokenService;
    private uriHelper:PathHelper;

    constructor(){
        this.url = '';
        this.baseUrl = baseURL+"/anabapi/v2";
        this.axiosInstance = axios.create({
            withCredentials: true,
            baseURL: this.baseUrl
        });
        this.tokenService = new TokenService(this);
        this.uriHelper = new PathHelper();
    }

    public async HttpSend(type:HttpType, uri:string, body:any = null):Promise<any>{
        await this.tokenService.EnsureFreshToken(uri, type);

        let response;

        switch(type){
            case HttpType.post: {
                response = await this.sendPostRequest(uri, body);
                break;
            }
            case HttpType.get: {
                response = await this.sendGetRequest(uri);
                break;
            }
            case HttpType.put: {
                response = await this.sendPutRequest(uri, body);
                break;
            }
            case HttpType.delete: {
                response = await this.sendDeleteRequest(uri, body);
                break;
            }
        }

        return this.convertErrorIfNeeded(response);
    }

    /*
    *   creates new axios instance and cleanly gets from an external API without response conversion or token workflow
    */
    public async GetFromExtrenalAPI(uri:string):Promise<any>{
        return await axios.create().get(uri);
    }

    /*--- PRIVATE ---*/

    private async axiosCall<T = unknown>(config: AxiosRequestConfig): Promise<HttpClientResponse<T>>
    {

        try {
            const { data } = await this.axiosInstance.request<T>(config);
            return [null, data];
        }
        catch (error:any) {
            return [new Error(error as string), error.response]; //return response as data to read HttpCode
        }
    }

    private getRequestConfig(requestMethod: Method, path: string, bodyContent: any) : AxiosRequestConfig
    {
        this.url = `${this.baseUrl}/${path}`;

        const globalStore = useGlobalStore();

        const token = globalStore.token;
        const authorizationHeader: string = 'Bearer ' + token;

        if(requestMethod === "get")
        {
            if(path === this.uriHelper.locations.getLocationPdfExportPath(PdfExportActionEnum.download)){
                return {
                    method: requestMethod,
                    responseType: "arraybuffer",
                    onDownloadProgress: (downloadEvent:any) => {
                        console.log("downloadEvent triggered", downloadEvent);
                    },
                    url: this.url,
                    headers: {
                        'Authorization': authorizationHeader
                    }
                }
            }

            return {
                method: requestMethod,
                url: this.url,
                headers: path === this.uriHelper.misc.getStartupConfigPath() ? {} : {
                    'Authorization': authorizationHeader
                }
            }
        }

        let contentType = "application/json";

        if(requestMethod === "post" && path === this.uriHelper.locations.getLocationImportPath()){
            contentType = "multipart/form-data";
        }

        return {
            method: requestMethod,
            url: this.url,
            data: bodyContent,
            onUploadProgress: (uploadEvent:any) => {
                if (path === this.uriHelper.locations.getLocationImportPath()) console.log("file upload progress triggered", uploadEvent);
            },
            headers: {
                'content-type': contentType,
                'Authorization': authorizationHeader
            }
        }
    }

    public async sendGetRequest<T = unknown>(path: string): Promise<HttpClientResponse<T>>
    {
        return await this.axiosCall<T>(this.getRequestConfig("get", path, null));
    }

    public async sendPostRequest<T = unknown>(path: string, bodyContent: any): Promise<HttpClientResponse<T>>
    {
        return await this.axiosCall<T>(this.getRequestConfig("post", path, bodyContent));
    }

    public async sendPutRequest<T = unknown>(path: string, bodyContent: any): Promise<HttpClientResponse<T>>
    {
        return await this.axiosCall<T>(this.getRequestConfig("put", path, bodyContent));
    }

    public async sendDeleteRequest<T = unknown>(path: string, bodyContent: any): Promise<HttpClientResponse<T>>
    {
        return await this.axiosCall<T>(this.getRequestConfig("delete", path, bodyContent));
    }

    // convert error to generalResponse if needed
    private convertErrorIfNeeded(resp:HttpClientResponse<any>):any {
        const error = resp[0];
        const data = resp[1];

        if(error) {
            const result = new GeneralResponse();
            result.statusCode = data?.status ?? 666666666666;
            result.statusDescription = `${error.message}`;

            return result;
        }

        return data;
    }
}