<template>
	<div>
		<div class="block w-full">

			<div class="grid grid-cols-2 gap-4 w-full">
				<div>
					<div class="inputTitle">
						{{ $t('popups.group2.description') }} *
					</div>
					<div class="inputField flex-grow" >
						<div :class="{ error: v$.name.$errors.length }">
							<input type="text" v-model="popupData.name" class="w-full" /><br/>
							<div class="input-errors" v-for="error of v$.name.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script setup>
import {computed, defineProps, onMounted, ref, toRef} from "vue";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {useGlobalStore} from "@/stores/GlobalStore";
import {useI18n} from "vue-i18n";


const globalStore = useGlobalStore();
const { t } = useI18n();

const props = defineProps({
	data: Object
})

const popupData = toRef(props, 'data');

onMounted(async () => {
	globalStore.modal.validator = v$;
})


// Validation

const rules = computed(() => {
	const rules = {
		name: { required: helpers.withMessage(t('validators.required'), required) },
	}
	return rules
})

const v$ = ref(useVuelidate(rules, popupData))
</script>

<style scoped>

</style>
