<template>
	<div>
		<div class="block w-full mb-12" v-if="selectedProviderId === ''">
      <h4 class="text-grey mb-4">{{ $t('popups.providerManagement.providerSearch') }}</h4>

      <div class="grid grid-cols-1 gap-4 w-full">
				<div>
					<div class="inputTitle">
						{{ $t('popups.providerManagement.providerList') }}
					</div>
					<div class="inputField flex-grow" >
            <Select :searchable="true" :selected="selectedProviderId" :options="foundExistingCompaniesList" mode="single" @selectChanged="selectProvider" @input="searchForExistingCompanies"/>
          </div>
				</div>
			</div>

		</div>
		<div class="block w-full">

			<div class="grid grid-cols-2 gap-4 w-full">
				<div>
					<div class="inputTitle">
						{{ $t('popups.providerManagement.name') }} *
					</div>
					<div class="inputField flex-grow" >
						<div :class="{ error: v$.name.$errors.length }">
							<input type="text" v-model="popupData.name" class="w-full" :disabled="popupData.hasAdmin || isSelectedProvider" /><br/>
							<div class="input-errors" v-for="error of v$.name.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.providerManagement.kid') }} *
					</div>
					<div class="inputField flex-grow" >
						<div :class="{ error: v$.companyIdCode.$errors.length }">
							<input type="text" v-model="popupData.companyIdCode" class="w-full"/><br/>
							<div class="input-errors" v-for="error of v$.companyIdCode.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.providerManagement.postalCode') }} *
					</div>
					<div class="inputField flex-grow" >
						<div :class="{ error: v$.zip.$errors.length }">
							<input type="text" v-model="popupData.zip" class="w-full" :disabled="popupData.hasAdmin || isSelectedProvider" /><br/>
							<div class="input-errors" v-for="error of v$.zip.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.providerManagement.city') }} *
					</div>
					<div class="inputField flex-grow" >
						<div :class="{ error: v$.city.$errors.length }">
							<input type="text" v-model="popupData.city" class="w-full" :disabled="popupData.hasAdmin || isSelectedProvider" /><br/>
							<div class="input-errors" v-for="error of v$.city.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.providerManagement.street') }} *
					</div>
					<div class="inputField flex-grow" >
						<div :class="{ error: v$.street.$errors.length }">
							<input type="text" v-model="popupData.street" class="w-full" :disabled="popupData.hasAdmin || isSelectedProvider" /><br/>
							<div class="input-errors" v-for="error of v$.street.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.providerManagement.houseNumber') }} *
					</div>
					<div class="inputField flex-grow" >
						<div :class="{ error: v$.streetnumber.$errors.length }">
							<input type="text" v-model="popupData.streetnumber" class="w-full" :disabled="popupData.hasAdmin || isSelectedProvider" /><br/>
							<div class="input-errors" v-for="error of v$.streetnumber.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.providerManagement.phonePrefix') }}
					</div>
					<div class="inputField flex-grow" >
							<Select :options="countryDialCodes"
                      :selected="selectedDialCode"
                      class="w-full"
                      mode="single"
                      @selectChanged="selectedDialCodeChanged"
                      :disabled="popupData.hasAdmin || isSelectedProvider"
              />
					</div>
				</div>
				<div>
					<div class="inputTitle">
						{{ $t('popups.providerManagement.phoneNumber') }}
					</div>
					<div class="inputField flex-grow" >
						<div :class="{ error: v$.phoneNumber.$errors.length }">
							<input type="text" v-model="popupData.phoneNumber" class="w-full" :disabled="popupData.hasAdmin || isSelectedProvider" placeholder="17012345678" /><br/>
							<div class="input-errors" v-for="error of v$.phoneNumber.$errors" :key="error.$uid">
								<div class="error-msg">{{ error.$message }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="grid grid-cols-1 gap-4 w-full mt-4">
				<div>
					<div class="inputTitle">
						{{ $t('popups.providerManagement.information') }}
					</div>
					<div class="inputField flex-grow" >
						<textarea rows="3" type="text" v-model="popupData.info" :disabled="popupData.hasAdmin || isSelectedProvider" class="w-full"></textarea><br/>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script setup>
import {computed, defineProps, onMounted, ref, toRef} from "vue";
import {helpers, required, numeric, minLength, maxLength} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {useGlobalStore} from "@/stores/GlobalStore";
import {useI18n} from "vue-i18n";
import Select from "@/components/inputs/Select";
import NullOrUndefinedHelper from "@/utilities/helpers/NullOrUndefinedHelper";
import {CompanyInfo} from "@/models/company/CompanyInfo";
import { countryDialCodes } from "@/AppConfig";
import {CompanyWorkerService} from "@/utilities/services/entities/CompanyWorkerService";
import {CompanyService} from "@/utilities/services/entities/CompanyService";

const globalStore = useGlobalStore();
const { t } = useI18n();
const companyWorkerService = new CompanyWorkerService();
const companyService = new CompanyService();
const isSelectedProvider = ref(false);

const props = defineProps({
	data: Object
})

const popupData = toRef(props, 'data');
const selectedProviderId = ref("");
const foundExistingCompaniesList = ref([]);

const selectedDialCode = computed(()=>{
  return countryDialCodes.find(cc => cc.dialCode === popupData.value.dialCode)?.id ?? "";
})
const selectedDialCodeChanged = (id)=>{
  popupData.value.dialCode = countryDialCodes.find(cc => cc.id === id)?.dialCode ?? "";
}

const selectProvider = async (id)=>{
	// set fields to readonly
	isSelectedProvider.value = true;

  // if deselect/'X'/clearing selection
  if(NullOrUndefinedHelper(id)) {
    // reset all inputs via the binding to entered data from previous edit view
    const resetObj = new CompanyInfo();
    for (const keyValueArray of Object.entries(resetObj)){
      popupData.value[keyValueArray[0]] = keyValueArray[1];
    }

    isSelectedProvider.value = false;

    return;
  }

  // get company from currently found list and set input fields via setting the props of popupData via reflection
  const foundCompany = foundExistingCompaniesList.value.find(p => p.id === id);

  if(foundCompany === undefined) return;

  // check if company has admin
  const hasAdminResp = await companyWorkerService.getCompanyHasUserWithRole(id, "Admin");
  // if call fails silently fail... sad but true (\m/,) for the moment
  if(hasAdminResp === null || hasAdminResp === undefined || hasAdminResp.statusCode !== 0) return;

  foundCompany.hasAdmin = hasAdminResp.isExist;

  // set popupData for every property used, since vue-2-way-binding (v-model) doesn't work on parent object (popupData)
  for (const keyValueArray of Object.entries(foundCompany)){
    if(keyValueArray[0] === "info") return;
    popupData.value[keyValueArray[0]] = keyValueArray[1];
  }
};

const searchForExistingCompanies = async ({target})=>{
  if(NullOrUndefinedHelper(target.value)) return;

  // CAUTION does not check for errors for uninterupted inputs & 'fire and forget'-workflow
  const resp = await companyService.getCompaniesByName(target.value);

  foundExistingCompaniesList.value = resp.companiesInfo;
}

onMounted(async () => {
	globalStore.modal.validator = v$;

  if (!NullOrUndefinedHelper(popupData.value.id)) {
    // check if company has admin
    const hasAdminResp = await companyWorkerService.getCompanyHasUserWithRole(popupData.value.id, "Admin");
    // if call fails silently fail... sad but true (\m/,) for the moment
    if (hasAdminResp === null || hasAdminResp === undefined || hasAdminResp.statusCode !== 0) return;
    popupData.value.hasAdmin = hasAdminResp.isExist;

    selectedProviderId.value = popupData.value.id;
  }
})


// Validation

const rules = computed(() => {
	const rules = {
		name: { required: helpers.withMessage(t('validators.required'), required) },
		streetnumber: { required: helpers.withMessage(t('validators.required'), required) },
		city: { required: helpers.withMessage(t('validators.required'), required) },
		street: { required: helpers.withMessage(t('validators.required'), required) },
		phoneNumber: {
			numeric: helpers.withMessage(t('validators.numeric'), numeric) ,
		},
    companyIdCode: {
			required: helpers.withMessage(t('validators.required'), required),
			numeric: helpers.withMessage(t('validators.numeric'), numeric) ,
			minLength: helpers.withMessage(t('validators.minLength', {amount: 8}), minLength(8)),
			maxLength: helpers.withMessage(t('validators.maxLength', {amount: 8}), maxLength(8)),
		},
		zip: {
			required: helpers.withMessage(t('validators.required'), required),
			numeric: helpers.withMessage(t('validators.numeric'), numeric) ,
		},
	}
	return rules
})

const v$ = ref(useVuelidate(rules, popupData))
</script>

<style scoped>

</style>
