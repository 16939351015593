import {WebCallService} from "@/utilities/services/WebCallService";
import {PathHelper} from "@/utilities/helpers/PathHelper";
import {
    CompaniesResponse,
    CompanyIdCodeResponse,
    CompanyIdResponse,
    CompanyInfoResponse,
    GeneralResponse
} from "@/models/api/Responses";
import {HttpType} from "@/utilities/enums/Enums";
import {CompanyInfo} from "@/models/company/CompanyInfo";
import NullOrUndefinedHelper from "@/utilities/helpers/NullOrUndefinedHelper";
import {CompanyRequest, ConnectSystemToCompanyRequest} from "@/models/api/Requests";

export class CompanyService {
    private webCaller: WebCallService;
    private uriHelper: PathHelper;

    constructor(){
        this.webCaller = new WebCallService();
        this.uriHelper = new PathHelper();
    }

    public async getCompanyDetails(viewType: string = "display"): Promise<CompanyInfoResponse> {
        const uri = this.uriHelper.company.getCompanyDetailsPath(viewType);

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async getCompaniesByName(name: string): Promise<CompaniesResponse> {
        const uri = this.uriHelper.company.getCompanyByNamePath(name);

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async getNewCompanyIdCode(): Promise<CompanyIdCodeResponse> {
        const uri = this.uriHelper.company.getCompanyIdCodePath();

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    public async addOrUpdateCompany(company: CompanyInfo): Promise<CompanyIdResponse | GeneralResponse> {
        const uri = NullOrUndefinedHelper(company.id)
            ? this.uriHelper.company.getCompanyPath()
            : this.uriHelper.company.putProviderByIdPath(company.id);

        const requestBody = new CompanyRequest(company);

        const reqType = NullOrUndefinedHelper(company.id)
            ? HttpType.post
            : HttpType.put;

        return await this.webCaller.HttpSend(reqType, uri, requestBody);
    }

    public async getCompanyById(id: string): Promise<CompanyInfoResponse> {
        const uri = this.uriHelper.company.getProviderByIdPath(id);

        return await this.webCaller.HttpSend(HttpType.get, uri);
    }

    // careful with this one: it hard-deletes the company entry not the anabsystemCompany one!
    public async deleteCompany(id: string): Promise<GeneralResponse> {
        const uri = this.uriHelper.company.getProviderByIdPath(id);

        return await this.webCaller.HttpSend(HttpType.delete, uri);
    }

    // correct 'deletion API', it only deletes the cross-table reference in anabsystemCompany and not globally deletes the company entity (which may be connected to other systems aswell)
    public async deleteSystemConnectionToCompany(id: string): Promise<GeneralResponse> {
        const uri = this.uriHelper.company.getProviderByIdPath(id);

        return await this.webCaller.HttpSend(HttpType.delete, uri);
    }

    public async postConnectSystemToCompany(systemId: (string | undefined) = undefined, companyId: string, companyIdCode: string, info: string): Promise<any> {
        const uri = this.uriHelper.company.getConnectSystemToCompanyPath();
        const requestBody = new ConnectSystemToCompanyRequest(systemId, companyId, companyIdCode, info);

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }

    public async postGetAllSimilarCompany(company: CompanyInfo): Promise<CompaniesResponse> {
        const uri = this.uriHelper.company.getAllSimilarCompanyPath();
        const requestBody = new CompanyRequest(company);

        return await this.webCaller.HttpSend(HttpType.post, uri, requestBody);
    }
}