export default {
  "sidebar": {
    "LocationRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortreports"])},
    "LocationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
    "RegistrationReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldereports"])},
    "SafetyInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitshinweise"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "ControlCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzleitstellen"])},
    "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Account"])},
    "Group2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ/Ebene"])},
    "Group1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzcenter/Region"])},
    "Activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeitsgruppen"])},
    "ProviderManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleister"])},
    "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
    "CategoryManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])}
  },
  "footer": {
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnissen"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog"])}
  },
  "login": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die smarte <br/>Online-Verwaltung <br/>der 1ANAB App"])},
    "subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1ANAB WEB"])},
    "tabs": {
      "login": {
        "tabTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail*"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort*"])},
        "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eingeloggt bleiben"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])}
      },
      "changePassword": {
        "tabTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für welche E-Mail-Adresse soll das Passwort geändert werden?"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail*"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
        "confirmHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail wurde versendet"])},
        "confirmDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihnen wurde eine E-Mail zugeschickt, die einen Link für die Passwortänderung enthält. Bitte prüfen Sie ihr Postfach."])},
        "confirmButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
        "newPasswordHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
        "newPasswordDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie das neue Passwort ein.<br/><br/>Folgeden Bedingungen müssen erfüllt werden:<br/>- mind. 8 Zeichen<br/>- mind. 1 Sonderzeichen<br/>- mind. eine Zahl (0-9)<br/>- mind. einen Großbuchstaben<br/>- mind. einen Kleinbuchstaben"])},
        "newPasswordLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort*"])},
        "newPasswordRepeatLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen*"])},
        "newPasswordSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
        "newPasswordCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
      }
    },
    "error": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das hat leider nicht funktioniert"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Ausführung Ihrer Anfrage ist ein Fehler aufgetreten. Versuchen Sie es später erneut oder kehren Sie zum Login zurück."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zum Login"])}
    },
    "notifySuccessfulLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie wurden erfolgreich angemeldet."])}
  },
  "404": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuups, Error 404."])},
    "subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da ist etwas schief gelaufen."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider konnte die angeforderte Seite nicht gefunden werden.<br/>Bitte versuchen Sie es später erneut oder navigieren Sie zur Startseite.<br/>"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Startseite"])},
    "buttonLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Login"])}
  },
  "403": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uuups, Error 403."])},
    "subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Berechtigung"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider sind Sie nicht Berechtigt diese Funktion zu nutzen.<br/>Bitte kontaktieren Sie ihren Vorgesetzten.<br/>"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Startseite"])}
  },
  "logout": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich ausgeloggt"])},
    "subline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis demnächst!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Login"])}
  },
  "changelog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog"])}
  },
  "settings": {
    "languageSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache aktualisiert"])},
    "startPageSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite aktualisiert"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "tabs": {
      "systemSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemeinstellungen"])},
      "speachTexts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachtexte"])},
      "coupledSystems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppelte Systeme"])},
      "providerSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistereinstellungen"])},
      "disdiSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATB Einstellungen"])}
    },
    "providerSettings": {
      "elapseTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablaufzeit in Tagen"])},
      "infoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweistext"])},
      "inactiveUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktive Benutzer"])}
    },
    "systemSettings": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert"])}
    },
    "coupledSystems": {
      "testSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test erfolgreich"])},
      "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie das folgende gekoppelte System '", _interpolate(_named("name")), "' wirklich löschen?"])},
      "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die ", _interpolate(_named("count")), " ausgewählten gekoppelten Systeme wirklich löschen?"])},
      "grid": {
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "loginName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldename"])},
        "testCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopplung testen"])},
        "testingCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte warten..."])}
      }
    },
    "disdiSettings": {
      "activityFeature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitäts Feature"])},
      "editDefaultActvity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardwert für Tätigkriten editierbar (nach Erstellung von Ameldung"])},
      "disdi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATB Feature"])},
      "readRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Wer darf:) Anlagentagebuch Einsehen"])},
      "editRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Wer darf:) ATB für Standorte aktivieren"])},
      "picturesAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Bilder pro ATB Eintrag"])},
      "metaTimestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten: Zeitstempel"])},
      "metaEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten: Ersteller persistierung"])},
      "metaInvisibleTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten: Zeiten-Sichtbarkeitsdauer"])},
      "metaInvisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten: Metadaten-Sichtbarkeitsdauer"])},
      "metaDeleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten: Metadaten-Ablaufzeit (Löschung der Metadaten)"])},
      "showInAppNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App: Maximale Anzahl der an die App übertragenen ATB-Einträge"])},
      "showInAppDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App: Maximales Alter der an die App übertragenen ATB-Einträge zur Übertragung"])},
      "useGalleryInApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App: Soll der Benutzer dem ATB einer neuen Kameraaufnahme auch bestehende Fotos hinzufügen dürfen?"])},
      "metaTimestampType": {
        "None": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine"])},
        "OnlyRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nur Anmeldung"])},
        "OnlyUnregistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nur Abmeldung"])},
        "Both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An- und Abmeldung"])}
      },
      "disdiType": {
        "NoDISDI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus"])},
        "SomeLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelne Standorte"])},
        "AllLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Standorte"])}
      }
    },
    "grid": {
      "intern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Kennung"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
      "speachText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprechtext"])}
    }
  },
  "validators": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld wird benötigt"])},
    "requiredIf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eines dieser Felder wird benötigt"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld muss eine gültige E-Mail Adresse sein."])},
    "sameAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Felder sind nicht identisch"])},
    "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Längegrad muss zwischen -180 und 180 sein."])},
    "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Breitengrad muss zwischen -90 und 90 sein."])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das ist keine gültige Telefonnummer. Format: +491234567891"])},
    "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Feld sind nur Zahlen erlaubt"])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dieses Feld darf maximal ", _interpolate(_named("amount")), " Zeichen haben"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dieses Feld muss minimum ", _interpolate(_named("amount")), " Zeichen haben"])},
    "hasNoneAlphaNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mind. 1 Sonderzeichen wird benötigt"])},
    "hasNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mind. 1 Zahl wird benötigt"])},
    "hasUppercaseLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mind. 1 Großbuchstabe wird benötigt"])},
    "hasLowercaseLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mind. 1 Kleinbuchstabe wird benötigt"])},
    "float": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine valide durch '.' getrennte Fließkommazahl erkannt. (Bsp. 0.1)"])}
  },
  "notifications": {
    "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort wurde erfolgreich zurückgesetzt!"])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
    "deleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle löschen"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichtentext"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "showDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details anzeigen"])},
    "applySafetyInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigung löschen"])},
    "yesButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
    "noButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEIN"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wollen Sie die ausgewählte Benachrichtigung wirklich löschen?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die ausgewählten ", _interpolate(_named("count")), " Benachrichtigungen wirklich löschen?"])},
    "promptInformationTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zu '", _interpolate(_named("locationNames")), "' wurde folgender gekoppelter Sicherheitshinweis übernommen:"])},
    "promptInformationBody": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Name: ", _interpolate(_named("name")), " ; Text: ", _interpolate(_named("text"))])},
    "promptInformationHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können den Sicherheitshinweis in den Standortdetails oder der Sicherheitshinweis-Verwatlung bearbeiten."])},
    "signalrDisconnected": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACHTUNG"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das automatische Nachladen hat die Verbindung verloren, bitte laden Sie die Seite neu."])}
    }
  },
  "account": {
    "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort wurde erfolgreich geändert!"])},
    "passwordResetButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
    "lastPasswordChangeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zuletzt geändert am"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort wiederholen"])},
    "passwordNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
    "passwordCurrent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Passwort"])},
    "passwordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
    "browserLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser Sprache"])},
    "startpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerkennung"])},
    "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Account"])}
  },
  "global": {
    "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespeichert"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesendet"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöscht"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "exportCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV herunterladen"])},
    "importCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV importieren"])},
    "downloadPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF herunterladen"])},
    "downloadPdfMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF per Mail verschicken"])},
    "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
    "unregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "noValidToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Sitzung ist abgelaufen oder fehlerhaft, bitte melden Sie sich erneut an!"])},
    "validationFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füllen Sie alle Felder korrekt aus"])},
    "units": {
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in Tagen"])}
    },
    "emptyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Einträge"])}
  },
  "locations": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
    "newLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Standort"])},
    "massDisdi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATB Einstellung für mehrere Standorte bearbeiten"])},
    "exportFilename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortexport"])},
    "changeCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppelung ändern"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie den folgenden Standort '", _interpolate(_named("name")), "' wirklich löschen?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die ", _interpolate(_named("count")), " ausgewählten Standorte wirklich löschen?"])},
    "grid": {
      "secId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "group2Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ/Ebene"])},
      "group1Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzcenter/Regionen"])},
      "customAdditionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technischer Platz"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "controlCenterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzleitstelle"])},
      "noCoupledSystems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Standort hat keine gekoppelten Systeme"])}
    },
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort(e) gelöscht"])},
    "willDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird herunterladen"])},
    "willBeSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschickt"])}
  },
  "safetyInstructions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitshinweise"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Sicherheitshinweis"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitshinweis bearbeiten"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie den folgenden Sicherheitshinweis '", _interpolate(_named("name")), "' wirklich löschen?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die ", _interpolate(_named("count")), " ausgewählten Sicherheitshinweise wirklich löschen?"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "safetyInstructionLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
    "selectedLocationsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ausgewählte Standorte"])},
    "csvDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV herunterladen"])},
    "csvDownloadFilename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitshinweise"])},
    "grid": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
    },
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitshinweis(e) gelöscht"])}
  },
  "providerManagement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleister"])},
    "grid": {
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "showStaff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter anzeigen"])},
      "companyIdCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])}
    },
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Dienstleister"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die Verbindung zum folgenden Dienstleister '", _interpolate(_named("name")), "' wirklich löschen?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die Verbindung zu den ", _interpolate(_named("count")), " ausgewählten Dienstleistern wirklich löschen?"])},
    "inactiveUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktive Benutzer vorhanden"])}
  },
  "providerManagementDetails": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistermitarbeiter"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Dienstleistermitareiter"])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht Dienstleistermitarbeiter"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktperson"])},
    "lessInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weniger Firmendetails"])},
    "moreInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehr Firmendetails"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "grid": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "cleared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe"])},
      "reactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reaktivieren"])}
    },
    "connectedWorker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestehender Mitarbeier wurde mit Ihrem System verbunden"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie folgenden Dienstleistermitarbeiter '", _interpolate(_named("name")), "' wirklich löschen?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die ", _interpolate(_named("count")), " ausgewählten Dienstleistermitarbeiter wirklich löschen?"])}
  },
  "locationsReports": {
    "secondaryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortreports"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "newLocationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuelle An-/Abmeldung"])},
    "newLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuelle An-/Abmeldung"])},
    "instantMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofortnachricht"])},
    "instantMessageDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiermit senden Sie einen Sofortnachricht an alle am Standort angemeldeten Benutzer. \nDen Nachrichtentext können Sie im unteren Fenster anpassen."])},
    "registerPopup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuelle An-/Abmeldung"])},
    "locationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort Details"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße/Nr."])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ/Ort"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "gps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GPS-Koordinaten"])},
    "securityInformations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitshinweise"])},
    "detailsHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "noGpsData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Koordinaten"])},
    "tabs": {
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer Historie"])},
      "locationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort Details"])},
      "safetyInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitshinweise"])},
      "safetyInstructionsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sicherheitshinweise an diesem Standort vorhanden."])}
    },
    "grid": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["letzte An-/Abmeldung"])},
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angemeldet"])},
      "unregistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgemeldet"])},
      "coupled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppelt"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "group1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzcenter/Region"])},
      "group2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ/Ebene"])},
      "controlCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzleitstelle"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "personCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen"])}
    }
  },
  "registrationReports": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldereports"])},
    "userHistoryPopup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer Historie"])},
    "userDetailPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer Details"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "grid": {
      "conferenceTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonkonferenz starten"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
      "locationId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort ID"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
      "unregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmeldung"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "guestCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gäste"])},
      "kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KID"])},
      "coupledUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppelter Benutzer"])}
    },
    "confirmUnregister": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie '", _interpolate(_named("name")), "' vom Standort '", _interpolate(_named("location")), "' abmelden?"])}
  },
  "users": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Benutzer"])},
    "exportCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV Exportieren"])},
    "csvDownloadFilename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie den folgenden Benutzer '", _interpolate(_named("name")), "' wirklich löschen?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die ", _interpolate(_named("count")), " ausgewählten Benutzer wirklich löschen?"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer gelöscht"])},
    "resetActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Benutzeraktivität wirklich zurücksetzen?"])},
    "resetMultipleActivity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie die Aktivität von ", _interpolate(_named("count")), " Benutzern wirklich zurücksetzen?"])},
    "inactiveUsersReactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte inaktive Benutzer wurden reaktiviert"])},
    "grid": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "identificationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
      "kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KID"])},
      "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "activeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer aktiv"])},
      "inactiveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer inaktiv, wird bald gelöscht"])}
    },
    "menu": {
      "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigung ändern"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität zurücksetzen"])}
    }
  },
  "regions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzcenter"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Netzcenter"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie das folgende Netzcenter '", _interpolate(_named("name")), "' wirklich löschen?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die ", _interpolate(_named("count")), " ausgewählten Netzcenter wirklich löschen?"])},
    "grid": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "syncId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SyncId"])}
    },
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzcenter gelöscht"])}
  },
  "activity": {
    "grid": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])}
    },
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die folgende Tätigkeit '", _interpolate(_named("name")), "' wirklich löschen?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die ", _interpolate(_named("count")), " ausgewählten Tätigkeiten wirklich löschen?"])}
  },
  "activitySet": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeitsgruppen"])},
    "tabs": {
      "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeitsgruppen"])},
      "manageActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeiten verwalten"])}
    },
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Tätigkeitsgruppe"])},
    "grid": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
      "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])}
    },
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die folgende Tätigkeitsgruppe '", _interpolate(_named("name")), "' wirklich löschen?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die ", _interpolate(_named("count")), " ausgewählten Tätigkeitsgruppen wirklich löschen?"])}
  },
  "controlCenters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzleitstellen"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die folgende Netzleitestelle '", _interpolate(_named("name")), "' wirklich löschen?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die ", _interpolate(_named("count")), " ausgewählten Netzleitstellen wirklich löschen?"])},
    "newcontrolCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Netzleitstelle"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "syncId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync ID"])},
    "grid": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "syncId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SyncId"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])}
    },
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzleitstelle(n) gelöscht"])}
  },
  "levels": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ/Ebene"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ(en) gelöscht"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Typ/Ebene"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ/Ebene Bearbeiten"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie den/die folgende Typ/Ebene '", _interpolate(_named("name")), "' wirklich löschen?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die ", _interpolate(_named("count")), " ausgewählten Typen/Ebenen wirklich löschen?"])},
    "grid": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])}
    }
  },
  "category": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie(n) gelöscht"])},
    "confirmDeletionSingle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die folgende Kategorie '", _interpolate(_named("name")), "' wirklich löschen?"])},
    "confirmDeletionMulti": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wollen Sie die ", _interpolate(_named("count")), " ausgewählten Kategorien wirklich löschen?"])},
    "grid": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
      "iconName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])}
    }
  },
  "disdi": {
    "view": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagentagebuch"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "grid": {
        "diaryDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
        "categoryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
        "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhänge"])},
        "createDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
        "registrationStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
        "registrationEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmeldung"])},
        "userFullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "userPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
        "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
        "categoryEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie nicht gefunden"])},
        "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])}
      },
      "csvExportFileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATB-Einträge"])},
      "pdfDownloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF erfolgriech runtergeladen"])}
    },
    "login": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte warten Sie und halten Sie Ihr Handy bereit, Sie werden zum Login weitergeleitet."])}
    },
    "maintenance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagentagebuch Nachpflege"])},
      "diaryDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhänge"])},
      "attachmentsPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhangvorschau"])},
      "disdiInfo": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
        "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An/Abmeldung"])}
      },
      "saveAndClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag Abschließen"])},
      "uploadDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HINWEIS: Sobald Sie ein oder mehrere Bilder ausgewählt haben werden Diese direkt hochgeladen. Dateien mit gleichem Namen werden überschrieben."])},
      "uploadedFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt hochgeladene Bilder"])},
      "generalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Informationen"])},
      "maintainInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu pflegende Informationen"])},
      "uploadButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhänge auswählen"])}
    },
    "exit": {
      "byeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Eintrag wurde geschlossen."])},
      "byeSubText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank und bis zum nächsten mal :)"])}
    }
  },
  "otpLogin": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])}
  },
  "error": {
    "status": {
      "general": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es ist ein Fehler aufgetreten. Servermeldung: '", _interpolate(_named("message")), "'"])},
      "accessDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten. Die Anfrage wurde abgelehnt."])},
      "-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Anfrage"])},
      "1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein interner Server-Error aufgetreten, bitte versuchen Sie es später erneut, oder Kontaktieren Sie den Support."])},
      "1001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angefragten Daten sind vorhanden. Bitte überprüfen Sie den Code oder wiederholen Sie den Vorgang."])},
      "1002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feld ValidationError"])},
      "1003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No System is attached to the User"])},
      "1004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Standorte vorhanden"])},
      "1005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ValidationError"])},
      "1006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LänderCode existiert Nicht"])},
      "3000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AnAbSystemId ValidationError"])},
      "3001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CompanyId ValidationError"])},
      "3002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AnAbSystem Existiert Nicht"])},
      "3003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma Existiert Nicht"])},
      "3004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer bereits vergeben"])},
      "3005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AnAbSystemCompany Existiert Nicht"])},
      "3006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Benutzer"])},
      "3007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein anderer Dienstleister hat diesen Benutzer"])},
      "3008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelte CompanyWorkerAnAbSystem"])},
      "3009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["InstallationId ValidationError"])},
      "3010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Firmen-ID ist ungültig."])},
      "3011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelte AnAbAppInstallation"])},
      "3012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Benutzer hat noch eine aktive Verbindung zu einem 1ANAB System. Bitte löschen sie zuerst alle Verbindungen"])},
      "3013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Benutzer ist als Kontaktperson ihrer Firma hinterlegt. Bitte bestimmen sie zuerst eine neue Kontaktperson, bevor sie diesen Benutzer löschen."])},
      "3014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Benutzer ist als Kontaktperson in einem 1ANAB System hinterlegt. Bitte bestimmen sie zuerst eine neue Kontaktperson, bevor sie diesen Benutzer löschen."])},
      "3016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuordnungen zum 1ANAB System sind noch vorhanden."])},
      "3017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Text konnte nicht gefunden werden."])},
      "3018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AnAbSystem Existiert in AnAbSystemDescription"])},
      "3019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt noch Zuordnungen, die die Aktion verhindern."])},
      "3020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AnAbSystem Existiert in CompanyWorkerAnAbSystem"])},
      "3021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PageDescription Existiert in AnAbSystemDescription"])},
      "3022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PageDescriptionId ValidationError"])},
      "3023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer existiert und Benutzer hat CompanyId"])},
      "3024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Name hat ein ungültiges Format."])},
      "3025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Postleitzahl hat ein ungültiges Format."])},
      "3026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Stadt hat ein ungültiges Format."])},
      "3027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Straße hat ein ungültiges Format."])},
      "3028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Hausnummer hat ein ungültiges Format."])},
      "3029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Länge des ANABSystemCompanyCode beträgt nicht 11-Stellen."])},
      "3030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer ist bereits in einem anderen System vorhanden."])},
      "3031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Persistenz-Eingabewert ist ungültig.Der eingegebene Wert muss kleiner als 365 sein."])},
      "3032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LastInteraction ValidationError"])},
      "4000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Existiert Nicht"])},
      "4001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UserId ValidationError"])},
      "4002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handynummer ValidationError"])},
      "4003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password ValidationError"])},
      "4004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RoleName ValidationError"])},
      "4005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer hat keine CompanyWorker Rolle"])},
      "4006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existiert mehr als ein Benutzer mit dieser Handynummer"])},
      "4007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die von Ihnen eingegebene Nummer scheint keine SMS empfangen zu können"])},
      "4008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die von Ihnen eingegebene Nummer war ungültig"])},
      "4009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername ValidationError"])},
      "4010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername ist bereits registriert"])},
      "4011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer ist bereits registriert"])},
      "4012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Nutzerrollen reichen nicht aus."])},
      "4013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länder-Vorwahl ValidationError"])},
      "4014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GSM-Nummer bereits vergeben."])},
      "4015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt einen Fehler im BenutzerHistorie"])},
      "4016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer ist nur für die ANAB"])},
      "4017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt keinen Admin-Benutzer"])},
      "4018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Link.Bitte rufen Sie sich bei Admin an"])},
      "4019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich Abgeschlossen. Ihnen wurde eine Administratorrolle zugewiesen"])},
      "4020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User hat das Produkt nicht"])},
      "4021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle hat das Produkt nicht"])},
      "4022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existiert mehrere Benutzer mit Benutzername"])},
      "4023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer kann nicht bearbeiten"])},
      "4024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UserAdditional ist Null"])},
      "4025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UserAdditional ValidationError"])},
      "4026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer mit dieser E-Mail Adresse ist bereits bekannt."])},
      "4028": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Benutzer existiert bereits bei einem anderem Dienstleister (", _interpolate(_named("companyName")), ")"])},
      "5000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler beim Anfragen der Systeme für den User aufgetreten."])},
      "5009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die maximale Anzahl an Standorten für Ihre Lizenz wurde erreicht."])},
      "6000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine offene Anmeldung gefunden"])},
      "6001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angegebene Standort ID konnte nicht erkannt werden"])},
      "6002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Angegebene System konnte nicht gefunden werden"])},
      "6003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die GPS Koordinaten sind fehlerhaft."])},
      "6666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein allgemeiner Fehler bei der An- und Abmeldung ist aufgetreten"])},
      "6667": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anmeldung konnte nicht erkannt werden"])},
      "6969": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer ist bereits an diesem Standort abgemeldet."])},
      "7000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification konnte nicht versendet werden"])},
      "7001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User konnte nicht gefunden werden"])},
      "7002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu viele Empfänger wurden ausgewählt (maximal 20)"])},
      "9000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie nur 5-stellige Identifikationsnummern an."])},
      "9001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt andere Datensatz, die aktuelle Standort-ID hat. Bitte tragen Sie andere Standort-ID an."])},
      "9002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort existiert nicht"])},
      "9003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Standort kann nicht gelöscht werden, solange noch Anmeldungen hinterlegt sind."])},
      "9004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt andere Datensatz, die aktuelle Sync-ID hat. Bitte tragen Sie andere Sync-ID an."])},
      "9005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppeltes System existiert Nicht"])},
      "9006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Standort"])},
      "9007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Gekoppeltes System"])},
      "9008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger System-Coupling-Identifier"])},
      "9009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Gekoppelter System Name"])},
      "9010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Verbindung besteht bereits, es ist nicht möglich, eine neue Verbindung zu erstellen."])},
      "9011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppeltes System Existiert Nicht"])},
      "9012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name ist pflichtfeld"])},
      "9013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text ist pflichtfeld"])},
      "9014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Identifier ValidationError"])},
      "9015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreign Location Identifier ValidationError"])},
      "9016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Id"])},
      "9017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SicherheitshinweisStandort Existiert Nicht"])},
      "9018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitshinweis Existiert Nicht"])},
      "9019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ/Ebene Existiert Nicht"])},
      "9020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datensatz konnte nicht gelöscht werden. Es sind noch Standorte verknüpft. Bitte erst alle Verbindungen aufheben."])},
      "9021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzleitstelle Existiert Nicht"])},
      "9022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datensatz konnte nicht gelöscht werden. Es sind noch Standorte verknüpft. Bitte erst alle Verbindungen aufheben."])},
      "9023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzcenter Existiert Nicht"])},
      "9024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datensatz konnte nicht gelöscht werden. Es sind noch Standorte verknüpft. Bitte erst alle Verbindungen aufheben."])},
      "9025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung Existiert Nicht"])},
      "9026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag ist pflichtfeld"])},
      "9027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer ist nicht für dieses AnAbSystem freigeschaltet."])},
      "9028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer ist bereits angemeldet."])},
      "9029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung System Identifier ValidationError"])},
      "9030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum ist pflichtfeld"])},
      "9031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupled Location Registration Existiert Nicht"])},
      "9032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS an nummer aus der Twilio Blacklist (z.B. Nummer unterdrückt)"])},
      "9033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger AnmeldungId"])},
      "9034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der gewählte Nutzer ist nicht für diesen Standort berechtigt."])},
      "9035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer ist noch angemeldet. Bitte zuerst dort abmelden."])},
      "9036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer ist bereits an diesem Standort angemeldet."])},
      "9037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer ist bereits an diesem Standort abgemeldet."])},
      "9038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ist pflichtfeld"])},
      "9039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Präferenztyp"])},
      "9040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabellenname ist pflichtfeld"])},
      "9041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Präferenzliste ist leer"])},
      "9042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attributname ist pflichtfeld"])},
      "9043": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail ist pflichtfeld"])},
      "9044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ist pflichtfeld"])},
      "9045": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bestätigen Sie Ihre E-mail-Adresse."])},
      "9046": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen ein neues Passwort wählen (alle 90 Tage)."])},
      "9047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family ist pflichtfeld"])},
      "9048": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer ist pflichtfeld"])},
      "9049": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht berechtigt, die Administratorrolle für einen Benutzer zu bearbeiten."])},
      "9050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datensatz konnte nicht gelöscht werden. Der Benutzer hat noch Anmeldungen."])},
      "9051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer StandortHatZugriffDefault existiert nicht"])},
      "9052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Users"])},
      "9053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Einstellungsname"])},
      "9054": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Einstellwert"])},
      "9055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellung Existiert Nicht"])},
      "9056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL ist pflichtfeld"])},
      "9057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BenutzerName ist pflichtfeld"])},
      "9058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt Telefonnummer ist pflichtfeld"])},
      "9059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System ist bereits verbunden"])},
      "9060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablaufhinweis ist pflichtfeld"])},
      "9061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablaufzeit ist pflichtfeld"])},
      "9062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Template ist pflichtfeld"])},
      "9063": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token ist pflichtfeld"])},
      "9064": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortwiederholung ist pflichtfeld"])},
      "9065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die eingegebenen Passwörter stimmen nicht überein."])},
      "9066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AccessToken ist pflichtfeld"])},
      "9067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RefreshToken ist pflichtfeld"])},
      "9068": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige RefreshToken"])},
      "9069": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Token"])},
      "9070": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kombination aus E-Mail und Passwort existiert nicht."])},
      "9071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde zu häufig das falsche Passwort eingegeben. Der Account wurde daher vorübergehend gesperrt."])},
      "9072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konnte ssoResult nicht anzeigen."])},
      "9073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Während der SAML-Authentification ist ein Fehler aufgetreten."])},
      "9074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzer konnte nicht über SAML authentifiziert werden."])},
      "9075": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kann keine Identifikationsnummer generiert werden."])},
      "9076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BenutzerExistiert."])},
      "9077": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer ist deaktiviert."])},
      "9078": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AnAbSystem Existiert nicht in CompanyWorkerAnAbSystem"])},
      "9079": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Keine Digit"])},
      "9080": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort ist pflichtfeld"])},
      "9081": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort ist pflichtfeld"])},
      "9082": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter sind gleich.Sie müssen ein neues Passwort eingeben. "])},
      "9083": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das alte Passwort ist falsch. "])},
      "9084": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie benutzen SAML."])},
      "9089": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail und Telefonnummer sind Pflichtangaben"])},
      "9100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO Anmeldung fehlgeschlagen. Es konnte keine Authorisierung gefunden werden."])},
      "9101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Ihren eigenen User nicht löschen."])},
      "9104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angegebene Identifikationsnummer existiert bereits"])},
      "9105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Format der angegebenen Identifikationsnummer ist falsch."])},
      "9106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung zu gekoppeltem System ist nicht möglich."])},
      "9107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Tätigkeitsname muss einzigartig sein"])},
      "10000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag nicht gefunden"])},
      "10001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhang nicht gefunden"])},
      "10002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATB ist ausgeschaltet"])},
      "10003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es dürfen keine Bilder hochgeladen werden"])},
      "10004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anfrage war nicht korrekt für dieses System"])},
      "10005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID ist nicht valide"])},
      "10008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag wurde nicht geschlossen"])},
      "10009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MetaTimestamp Error"])},
      "10010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es konnte keine Vorlage für das PDF gefunden werden."])},
      "11001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild wurde bereits gelöscht, oder konnte nicht gefunden werden."])},
      "12002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kategorie wir noch von einer Tätigkeit verwendet."])},
      "999999": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei upload ist fehlgeschlagen, bitte versuchen Sie es später erneut."])},
      "666666666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boolischer Wert muss entweder 'True'/'true' oder 'False'/'false' enthalten"])},
      "666666666666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es konnte keine Verbindung hergestellt werden, bitte überprüfen Sie Ihre Internetverbindung"])}
    }
  },
  "popups": {
    "settings": {
      "spokenText": {
        "intern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Kennung"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
        "spokenText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprechtext"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprechtexte bearbeiten"])}
      },
      "systemSettings": {
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellung bearbeiten"])},
        "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
      },
      "coupledSystems": {
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppeltes System bearbeiten"])},
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues System koppeln"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Anwendung"])},
        "baseUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis URL"])},
        "partnerSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner"])},
        "nameOfPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Ansprechpartners"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "loginData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldedaten"])},
        "loginName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Name"])},
        "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
        "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelde-Informationen für Fremdsysteme"])},
        "resetPasswordText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte senden Sie uns ein Ticket und wir lassen Ihnen Ihre Zugangsdaten schnellstmöglich zukommen."])},
        "resetPasswordHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])}
      }
    },
    "registrationReports": {
      "grid": {
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
        "registerUnregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
        "unregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmeldung"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
        "guestCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gäste"])}
      },
      "changeActivity": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbestimmte Tätigkeit ändern"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie entweder eine bestehende Tätigkeit per Dropdown aus, oder Erstellen Sie eine neue Tätigkeit über die Freitext-Eingabe."])},
        "currentActivityTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Tätigkeit:"])},
        "takeGivenActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandene Tätigkeit auswählen:"])},
        "createNewActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Tätigkeit erstellen:"])}
      }
    },
    "providerManagementDetails": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistermitarbeiter anlegen"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistermitarbeiter bearbeiten"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "dialCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landesvorwahl"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "workerData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter Daten"])},
      "enabling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablaufstatus"])},
      "inviteAsAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Admin einladen"])},
      "invitationInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mitarbeiter wurde am ", _interpolate(_named("dateString")), " eingeladen."])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "companyHasAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Benutzer könne nur von einem Dienstleisteradministrator angelegt werden, bitte wenden Sie sich an:"])}
    },
    "providerManagement": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleister bearbeiten"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Dienstleister"])},
      "gsmSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GSM-Suche"])},
      "similarCompanies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ähnliche bestehende Dienstleister gefunden"])},
      "selectedSimilarProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählter Dienstleister:"])},
      "editInsertedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eingebene Daten bearbeiten"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistername"])},
      "kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
      "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
      "houseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer"])},
      "phonePrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt: Landesvorwahl"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt: Telefonnummer"])},
      "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
      "providerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste bekannter Dienstbetreiber"])},
      "providerSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistersuche"])},
      "chooseAvailableProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandenen Dienstleister auswählen"])}
    },
    "locations": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
      "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])}
    },
    "locationInfo": {
      "section1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDs & GPS"])},
      "section2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name & Anschrift"])},
      "section3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fallbacktelefonnummern & Kopplung"])},
      "section4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusatz Spezifikation"])},
      "secondaryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort ID"])},
      "syncId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync ID"])},
      "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Längengrad"])},
      "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breitengrad"])},
      "registrationRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelderadius (in KM)"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "namePronunciation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aussprache"])},
      "streetNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
      "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "phoneNumberAltIsFallback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersatz Telefonnummer"])},
      "couplingEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopplung aktiviert"])},
      "phoneNumberAlt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative Telefonnummer 2"])},
      "phoneNumberAlt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative Telefonnummer"])},
      "customAdditionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technischen Platz"])},
      "specificOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
      "group1AndName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzcenter/Region"])},
      "group2IdAndName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ/Ebene"])},
      "controlCenterIdAndName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzleitstelle"])},
      "safetyInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitshinweise"])},
      "pdfTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF Vorlage"])},
      "readerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorleser"])},
      "readerTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine Telefonnummer mit Vorwahl an (+491231231234). Das Feld 'Aussprache' wird Ihnen dann vom Telefonautomaten vorgelesen (per Anruf)."])},
      "readerPhonenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "activitySet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeitsgruppe"])},
      "hasDisdi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagentagebuch"])}
    },
    "activitySet": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
      "addActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeit zuweisen/erstellen"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
      "group2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ/Ebene"])},
      "group1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzcenter/Region"])},
      "couple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koppeln"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Aktivitätsgruppe"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitätsgruppe bearbeiten"])},
      "locationsHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortzuweisung"])},
      "toggleActivityFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auch Standorte mit anderer Tätigkeitsgruppe anzeigen"])}
    },
    "activity": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung der Tätigkeit"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeit bearbeiten"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Tätigkeit"])}
    },
    "register": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldungen Filter"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
      "guestAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Gäste"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
      "unregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
      "adressOfLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse des angemeldeten Standorts"])},
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angemeldet"])},
      "unregistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgemeldet"])}
    },
    "registerLocation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortreport Filter"])}
    },
    "registerFilter": {
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "identificationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
      "kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KID"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeit"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
      "guestCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl Gäste"])}
    },
    "confirm": {
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])}
    },
    "csv": {
      "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV Datei"])}
    },
    "userFilter": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer Filter"])},
      "groudKid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KID-Vorhanden"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
      "groupActivitystatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitätszustand"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
      "identificationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])}
    },
    "reader": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorleser"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine Telefonnummer mit Vorwahl an (+491231231234). Das Feld 'Aussprache' wird Ihnen dann vom Telefonautomaten vorgelesen (per Anruf)."])},
      "callNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrufen und vorlesen lassen"])}
    },
    "userEditor": {
      "title": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer anlegen"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer bearbeiten"])}
      },
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "namePronunciation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Aussprache"])},
      "identificationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
      "kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KID"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen"])},
      "hasProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreibschutz beim Import?"])}
    },
    "locationFilter": {
      "secondaryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "controlCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzleitstelle"])},
      "coupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopplung"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort Filter"])},
      "group1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzcenter/Region"])},
      "group2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ/Ebene"])},
      "safetyInstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitshinweise"])},
      "customAdditionalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technischer Platz"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])}
    },
    "locationEditor": {
      "title": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort Anlegen"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort Editieren"])}
      }
    },
    "locationCsvImport": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort CSV Import"])}
    },
    "locationCoupling": {
      "grid": {
        "foreignSystemName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quellsystem"])},
        "locationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
        "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])}
      },
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortkoppelung Bearbeiten"])},
      "showCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopplungen anzeigen"])},
      "hideCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopplungen minimieren"])},
      "createNewCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Standortkopplung erstellen"])},
      "activeCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Kopplungen"])},
      "newCoupling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Kopplung"])},
      "sourceSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quellsystem"])}
    },
    "controlCenterEditor": {
      "title": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzleitstelle Anlegen"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzleitstelle Editieren"])}
      }
    },
    "controlCenterFilter": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzleitstelle Filter"])}
    },
    "regionEditor": {
      "title": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Netzcenter"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzcenter bearbeiten"])}
      }
    },
    "locationReportsFilter": {
      "group1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzcenter/Region"])},
      "group2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ/Ebene"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "controlCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzleitstelle"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
      "lastRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["letzte An-/Abmeldung"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])}
    },
    "group1": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "syncId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SyncId"])}
    },
    "group2": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])}
    },
    "categoryEditor": {
      "title": {
        "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Kategorie"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie bearbeiten"])}
      },
      "titleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
      "iconName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])}
    },
    "disdiFilter": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATB Filter"])},
      "diaryDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
      "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhangnamen"])},
      "createDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
      "registrationStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
      "registrationEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmeldung"])},
      "userFullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "userPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])}
    },
    "disdiDetails": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATB-Details"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
      "atbTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATB-Details"])},
      "createDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
      "tabs": {
        "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
        "locationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortdetails"])},
        "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhänge"])}
      },
      "location": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortdetails"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortname"])},
        "group2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ/Ebene"])},
        "group1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzcenter/Region"])},
        "CCName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzleitstelle"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])}
      },
      "user": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiterdetails"])},
        "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
        "identificationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
        "kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KID"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])}
      },
      "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AnAbmeldungsdetails"])},
      "registrationStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldedatum"])},
      "registrationEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmeldedatum"])}
    },
    "locationDisdiEditor": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort ATB Freigabe"])}
    },
    "general": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Default zurücksetzen"])},
      "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
    }
  }
}