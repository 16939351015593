import { RegistrationStatusType } from "@/utilities/enums/Enums";
import { LocationRegistrationStatus } from "@/models/registration/LocationRegistrationStatus";
import dateToString from "@/utilities/helpers/DateToStringHelper";
import {DisdiInfo} from "@/models/disdi/DisdiInfo";
import IsNullOrUndefined from "@/utilities/helpers/NullOrUndefinedHelper";

export class FilterMethodBuilder {
    location: LocationFilters = new LocationFilters();
    user: UserFilters = new UserFilters();
    register: RegisterReportFilters = new RegisterReportFilters();
    registerLocation: RegisterLocationReportFilters = new RegisterLocationReportFilters();
    disdi: DisdiFilters = new DisdiFilters();
}

class LocationFilters {
    filterStatus(value:any) {
        return (record:any) => {
            const obj = record.data;
            const regStatus = new LocationRegistrationStatus(obj.registrationStatus);

            switch (value) {
                case RegistrationStatusType.isBusy:
                    return regStatus.isBusy();

                case RegistrationStatusType.wasBusy:
                    return regStatus.unregisteredCount > 0 && regStatus.registeredCount === 0;

                case RegistrationStatusType.notBusy:
                    return regStatus.totalRegistrationsCount() === 0;

                default:
                    console.log("invalid regStatusType given when filtering:", value);
                    break;
            }
        };
    }

    filterControlCenter(value:number) {
        return (record:any) => {
            const obj = record.data;
            
            return obj.controlCenterId === value;
        };
    }

    filterGroup1(value:number) {
        return (record:any) => {
            const obj = record.data;
            
            return obj.group1Id === value;
        };
    }

    filterGroup2(value:number) {
       return (record:any) => {
            const obj = record.data;
            
            return obj.group2Id === value;
        }; 
    }
}

class UserFilters {
    filterIsActive(value:boolean){
        return (record:any) => {
            const obj = record.data;

            return obj.isActive === value;
        };
    }

    filterRoles(value:string){
        return (record:any) => {
            const obj = record.data;

            return obj.roles.includes(value);
        };
    }

    filterHasKID(value:boolean){
        return (record:any) => {
            const obj = record.data;

            return value ? !IsNullOrUndefined(obj.kid) : IsNullOrUndefined(obj.kid);
        };
    }
}

class RegisterReportFilters {
    filterIsActive(value:boolean){
        return (record:any) => {
            const obj = record.data;

            return obj.isActive === value;
        };
    }
    filterToday(){
        return (record:any)=>{
            const obj = record.data;

            const regDate = dateToString(new Date(obj.lastRegisteredLocationDate)).slice(0,-6);
            const nowDate = dateToString(new Date()).slice(0,-6);

            return regDate === nowDate;
        }
    }
}

class RegisterLocationReportFilters{
    filterIsActive(value:boolean){
        return (record:any) => {
            const obj = record.data;

            return obj.registrationStatus.isLocationBusy === value;
        };
    }
    filterToday(){
        return (record:any)=>{
            const obj = record.data;

            const regDate = dateToString(new Date(obj.lastRegisteredDate)).slice(0,-6);
            const nowDate = dateToString(new Date()).slice(0,-6);

            return regDate === nowDate;
        }
    }
    filterControlCenter(value:number) {
        return (record:any) => {
            const obj = record.data;

            return obj.controlCenterId === value;
        };
    }
    filterGroup1(value:number) {
        return (record:any) => {
            const obj = record.data;

            return obj.group1Id === value;
        };
    }
    filterGroup2(value:number) {
        return (record:any) => {
            const obj = record.data;

            return obj.group2Id === value;
        };
    }
}

class DisdiFilters{
    filterCategory(value:string) {
        return (record:any) => {
            const obj = record.data as DisdiInfo;

            return obj.categoryId === value;
        };
    }
}
