import { RegisteredLocationInfo } from "../registration/RegisteredLocationInfo";
import {SelectableRoleInfo} from "@/models/SelectableRoleInfo";

export class UserInfo {
    id: string = "";
    firstName: string = "";
    lastName: string = "";
    fullName: string = `${this.firstName} ${this.lastName}`;
    namePronunciation: string = "";
    identificationNumber: string = "";
    kid: string|null = null;
    email: string = "";
    dialCode:string = "";
    phoneNumber: string = ""; // sadly sometimes with and sometimes without dialCode, seperation of the 2 not globally implemented yet (15.03.2023)
    registeredLocationInfo: Array<RegisteredLocationInfo> = [];
    companyId?:string = undefined;
    roles: Array<SelectableRoleInfo> = [];
    info: string = "";
    deletedDays: number = 0;
    isActive: boolean = false;
    deleteWarning: boolean = false;
    deleteDate: string = "";
    isRegistered: boolean = false;
    hasProtection: boolean = false;
    lastPasswordChange:string = "";
}
